import React, { Component } from "react";
import { PureAppComponent } from "../../../Components/AppComponent";
import SortAndFilter from "./SortAndFilter";
import "./style.css";

class FilterBar extends PureAppComponent {
  state = { sortBox: false };

  render() {
    const {
      props: { leftItem },
    } = this;
    return (
      <div
        className="leadbuttonbar"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {leftItem ? leftItem() : null}
        <div className="leadbuttonrow" style={{ display: "flex" }}>
          <div
            className="leadbutton"
            onClick={() =>
              !this.state.sortBox && this.setState({ sortBox: true })
            }
          >
            <img
              className="leadbuttonico"
              src={require("../../../Assets/Images/sortico.png")}
              alt="sort"
            />
            <div className="leadbuttontext">{"Filter"}</div>

            <SortAndFilterPopup
              visible={this.state.sortBox}
              onClose={() => this.setState({ sortBox: false })}
              query={this.props.query}
              onChange={this.props.setQuery}
              filterOptions={this.props.filterOptions}
              orderOptions={this.props.orderOptions}
              defaultQuery={this.props.defaultQuery}
            />
          </div>
        </div>
      </div>
    );
  }
}

class SortAndFilterPopup extends Component {
  constructor() {
    super();
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    const {
      props: { visible },
    } = this;

    if (!visible) return null;

    return (
      <div
        ref={this.wrapperRef}
        className="leadsortpopup"
        style={{
          position: "absolute",
          top: "40px",
          right: 0,
        }}
      >
        <SortAndFilter
          query={this.props.query}
          onChange={this.props.onChange}
          onClose={this.props.onClose}
          filterOptions={this.props.filterOptions}
          orderOptions={this.props.orderOptions}
          defaultQuery={this.props.defaultQuery}
        />
      </div>
    );
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef?.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.props.onClose();
    }
  }
}

export default FilterBar;
