import React from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import { PureAppComponent } from "../../../Components/AppComponent";
import RenderSymbolQueue from "./RenderSymbolQueue";

const SCREEN_NAME = "ORDER_QUEUE_SCREEN";

class RenderSymbolQueuesInner extends PureAppComponent {
  state = { loading: false, error: null };

  componentDidMount() {
    this.onMount();
    this.load({ reload: true });
  }

  render() {
    const {
      state: { loading, error },
      props: {
        selectedSymbols = [],
        symbolQueuObj = {},
        futuresAllTickers = {},
      },
    } = this;
    return (
      <div>
        <div>{loading ? "Loading..." : null}</div>
        <div className="errormsg">{error}</div>
        <div
          style={{
            borderRadius: "5px",
            border: "1px solid #3333",
            padding: "10px",
            margin: "10px 0",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {selectedSymbols.map((symbol) => {
            return (
              <RenderSymbolQueue
                currentPrice={parseFloat(futuresAllTickers[symbol]?.c)}
                openingPrice={parseFloat(futuresAllTickers[symbol]?.o)}
                key={symbol}
                symbol={symbol}
                data={symbolQueuObj[symbol]}
              />
            );
          })}
        </div>
      </div>
    );
  }

  query = {};
  defaultQuery = {};
  handleQuery(obj) {
    if (!obj) return null;
    this.query = update(this.query, { $merge: obj });

    this.load({ reload: true });
  }

  loadThrottleTimer = null;
  async load(opt) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(opt);
    }, 200);
  }

  async retrieveData() {
    try {
      // await this.setAsyncState({ loading: true, error: null });
      // await this.setAsyncState({ loading: false });
    } catch (e) {
      console.warn(e);
      await this.setAsyncState({ loading: false, error: e.message });
    }
  }
}

const mapStateToProps = (state) => ({
  selectedSymbols: state.vState[SCREEN_NAME]?.selectedSymbols,
  symbolQueuObj: state.vState[SCREEN_NAME]?.symbolQueuObj,
  futuresAllTickers: state.vState.BINANCE_DATA?.futuresAllTickers,
  onFuturesAllTickers: state.vState.BINANCE_DATA?.onFuturesAllTickers,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const RenderSymbolQueues = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(RenderSymbolQueuesInner));

export default RenderSymbolQueues;
