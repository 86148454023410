import React, { Component } from "react";
import PropTypes from "prop-types";
import "./autocomplete.css";

class AutocompleteInput extends Component {
  static propTypes = {
    getSuggestions: PropTypes.func,
    onSelect: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    multiselect: PropTypes.bool,
    selectedItems: PropTypes.array,
    delay: PropTypes.number,
    initialValue: PropTypes.string,
    readOnly: PropTypes.bool,
    ListHeader: PropTypes.func,
    listHeaderProps: PropTypes.object,
    showSelectedItemsHeader: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      activeSuggestion: 0, // The active selection's index
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: props.initialValue || "",
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.initialValue && this.props.initialValue) {
      this.setState({ userInput: this.props.initialValue });
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  searchTimer = null;
  // Event fired when the input value is changed
  onChange = (e, value = null) => {
    const userInput = e?.currentTarget?.value || value;
    this.setState({
      activeSuggestion: 0,
      showSuggestions: true,
      userInput,
    });

    if (this.searchTimer) clearTimeout(this.searchTimer);

    this.searchTimer = setTimeout(async () => {
      try {
        this.setState({ loading: true });
        if (this.props.onChange) this.props.onChange(userInput);

        const suggestions = await this.props.getSuggestions(userInput);

        const filteredSuggestions = suggestions;
        console.info({ suggestions });

        // Update the user input and filtered suggestions, reset the active
        // suggestion and make sure the suggestions are shown
        this.setState({
          loading: false,
          activeSuggestion: 0,
          filteredSuggestions,
          showSuggestions: true,
        });
      } catch (error) {
        console.warn(error);
      }
    }, this.props.delay || 350);
  };

  // Event fired when the user clicks on a suggestion
  onClick = (obj, e) => {
    e.stopPropagation();
    // Update the user input and reset the rest of the state
    this.props.onSelect(obj);
    this.setState({
      activeSuggestion: 0,
      // filteredSuggestions: [],
      showSuggestions: false,
      userInput: this.props.multiselect
        ? this.state.userInput
        : obj.selectLabel || obj.value,
    });
  };

  // Event fired when the user presses a key down
  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      e.preventDefault();
      if (!filteredSuggestions[activeSuggestion]) return;

      this.props.onSelect(filteredSuggestions[activeSuggestion]);

      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: this.props.multiselect
          ? this.state.userInput
          : filteredSuggestions[activeSuggestion].value,
      });
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
        focused,
      },
      props: {
        multiselect,
        selectedItems,
        ListHeader,
        listHeaderProps,
        showSelectedItemsHeader = true,
      },
    } = this;

    let selectedItemListComponent =
      selectedItems && selectedItems.length
        ? selectedItems.map((suggestion, i) => {
            return (
              <li
                key={suggestion._id || suggestion.value || i}
                onClick={(e) => onClick(suggestion, e)}
              >
                {multiselect ? (
                  <div
                    className={
                      "asdecor " + (this.isSelected(suggestion) ? "active" : "")
                    }
                  >
                    <div className="asdecorinner"></div>
                  </div>
                ) : null}
                {suggestion.label || suggestion.value}
              </li>
            );
          })
        : null;

    let suggestionsListComponent;

    if (
      (showSuggestions &&
        (focused || !this.props.hideOnBlur) &&
        !multiselect) ||
      (focused && multiselect)
    ) {
      if (filteredSuggestions.length) {
        console.log({ ListHeader });

        suggestionsListComponent = (
          <ul className="suggestions">
            {ListHeader ? (
              <ListHeader
                userInput={userInput}
                onSelect={this.onListHeaderClick.bind(this)}
                listHeaderProps={listHeaderProps}
              />
            ) : null}

            {showSelectedItemsHeader ? selectedItemListComponent : null}

            {this.state.loading ? <li>Loading...</li> : null}
            {filteredSuggestions.map((suggestion, index) => {
              if (
                multiselect &&
                this.isSelected(suggestion) &&
                showSelectedItemsHeader
              ) {
                return null;
              }

              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  className={className}
                  key={suggestion._id || suggestion.value || index}
                  onClick={(e) => onClick(suggestion, e)}
                >
                  {multiselect ? (
                    <div
                      className={
                        "asdecor " +
                        (this.isSelected(suggestion) ? "active" : "")
                      }
                    >
                      <div className="asdecorinner"></div>
                    </div>
                  ) : null}

                  {suggestion.label || suggestion.value}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="suggestions">
            {ListHeader ? (
              <ListHeader
                userInput={userInput}
                onSelect={this.onListHeaderClick.bind(this)}
              />
            ) : null}

            {selectedItemListComponent}

            {this.state.loading ? (
              <span>Loading...</span>
            ) : (
              <span>No Result</span>
            )}
          </div>
        );
      }
    }

    return (
      <div className="referencewrapper" ref={this.setWrapperRef}>
        <input
          type="text"
          onChange={onChange}
          placeholder={this.props.placeholder}
          onKeyDown={onKeyDown}
          value={userInput || ""}
          className={this.props.className}
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          readOnly={this.props.readOnly}
        />
        {suggestionsListComponent}
      </div>
    );
  }

  handleFocus() {
    if (this.props.multiselect || this.props.hideOnBlur) {
      this.setState({ focused: true, showSuggestions: true });

      // if (this.props.multiselect)
      this.onChange(null, this.state.userInput || "");
    }
  }
  handleBlur() {
    // if (this.props.multiselect) {
    //   setTimeout(() => {
    //     this.setState({ showSuggestions: false, focused: false });
    //   }, 100);
    // }
  }
  isSelected(suggestion) {
    return !!this.props.selectedItems?.find(
      (x) => suggestion.value === x.value
    );
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.props.multiselect || this.props.hideOnBlur) {
        this.setState({
          showSuggestions: false,
          focused: false,
          userInput: this.props.multiselect ? "" : this.state.userInput,
        });
      }
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  onListHeaderClick(x, opt) {
    this.props.onSelect(x);

    this.setState({
      activeSuggestion: 0,
      // filteredSuggestions: [],
      showSuggestions: false,
      userInput: opt?.userInput || "",
    });
  }
}

export default AutocompleteInput;
