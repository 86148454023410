import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Login from "../Views/Auth/LoginScreen";
import MainScreenNavigator from "./MainScreenNavigator";

function AppNavigator() {
  return (
    <Router>
      <Switch>
        <Route path="/login" exact component={Login} />


        <Route path="/" component={MainScreenNavigator} />
      </Switch>
    </Router>
  );
}

export default AppNavigator;
