import React from "react";
import { connect } from "react-redux";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import { PureAppComponent } from "../../../Components/AppComponent";
import api from "../../../Services/Api/api";
import { Container, Navbar, Table, Nav } from "react-bootstrap";

const SCREEN_NAME = "BALANCES_SCREEN";

class BalanceDetailsInner extends PureAppComponent {
  state = {
    loading: false,
    error: null,
    positions: [],
    orders: [],
  };

  componentDidMount() {
    this.load();
    this.onMount();
  }

  positionColumns = [
    { value: "symbol" },
    { value: "positionAmt" },
    { value: "unRealizedProfit" },
    { value: "positionSide" },

    { value: "leverage" },
    { value: "entryPrice" },
    { value: "markPrice" },
    { value: "liquidationPrice" },

    {
      value: "initialAmount",
      render: ({ item }) =>
        parseFloat(item.entryPrice * item.positionAmt).toFixed(2),
    },
    {
      value: "currentPrice",
      render: ({ item }) =>
        parseFloat(this.props.futuresAllTickers[item.symbol]?.c),
    },
    {
      value: "currentChange",
      render: ({ item }) =>
        parseFloat(
          item.positionAmt *
            (parseFloat(this.props.futuresAllTickers[item.symbol]?.c) -
              parseFloat(item.entryPrice))
        ).toFixed(2),
    },
  ];

  orderColumns = ["symbol", "orderAmount", "price", "quantity"];

  async marketSell(row) {
    try {
      const payload = {
        symbol: row.symbol,
        side: "SELL",
        positionSide: row.positionSide,
        type: "MARKET",
        quantity: row.positionAmt,
      };

      const confirmed = window.confirm(
        `Please review order payload and confirm. \n ${JSON.stringify(
          payload,
          null,
          8
        )}`
      );

      if (confirmed) {
        const res = await api.post("v3/account/marketSell", {
          orderPayload: payload,
          accountId: this.props.account._id,
        });

        this.load();

        window.alert(`${JSON.stringify(res, null, 8)}`);
      }
    } catch (e) {
      window.alert(e.message);
    }
  }

  async cancelOrder(row) {
    try {
      const payload = {
        symbol: row.symbol,
        orderId: row.orderId,
      };

      const confirmed = window.confirm(
        `Please review order payload and confirm. \n ${JSON.stringify(
          payload,
          null,
          8
        )}`
      );

      if (confirmed) {
        const res = await api.post("v3/account/cancelOrder", {
          orderPayload: payload,
          accountId: this.props.account._id,
        });

        this.load();

        window.alert(`${JSON.stringify(res, null, 8)}`);
      }
    } catch (e) {
      window.alert(e.message);
    }
  }

  openPositions() {
    const {
      state: { positions },
    } = this;

    return (
      <div>
        <Table>
          <tbody>
            <tr>
              {this.positionColumns.map((x) => (
                <th key={x.value}>{x.value}</th>
              ))}
              <th>Actions</th>
            </tr>
            {positions?.map((row, index) => {
              return (
                <tr key={row.symbol} onClick={() => console.log(row)}>
                  {this.positionColumns.map((x) => (
                    <td key={x.value}>
                      {x.render ? x.render({ item: row, index }) : row[x.value]}
                    </td>
                  ))}
                  <td>
                    <button onClick={() => this.marketSell(row)}>
                      Market Sell
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  openOrders() {
    const {
      state: { orders },
    } = this;
    return (
      <div>
        <Table>
          <tbody>
            <tr>
              {this.orderColumns.map((x) => (
                <th key={x}>{x}</th>
              ))}
              <th>Actions</th>
            </tr>
            {orders?.map((row) => {
              return (
                <tr key={row.symbol} onClick={() => console.log(row)}>
                  {this.orderColumns.map((x) => (
                    <td key={x}>{row[x]}</td>
                  ))}
                  <td>
                    <button onClick={() => this.cancelOrder(row)}>
                      Cancel Order
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
          <Container>
            <Navbar.Toggle aria-controls="account-balance-details-modal" />
            <Navbar.Collapse id="account-balance-details-modal">
              <Nav className="me-auto">
                <Nav.Link
                  onClick={() => this.setState({ activetab: "position" })}
                >
                  Positions
                </Nav.Link>
                <Nav.Link onClick={() => this.setState({ activetab: "order" })}>
                  Orders
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div style={{ overflowX: "auto" }}>
          {this.state.activetab === "order"
            ? this.openOrders()
            : this.openPositions()}
        </div>
      </div>
    );
  }

  loadThrottleTimer = null;
  async load(fields) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retriveData(fields);
    }, 200);
  }

  async retriveData(fields) {
    try {
      await this.setAsyncState({ loading: true, error: null });
      const positionPromise = api.get("v3/account/futuresPositionRisk", {
        accountId: this.props.account?._id,
      });
      const ordersPromise = api.get("v3/account/futuresOpenOrders", {
        accountId: this.props.account?._id,
      });
      const { positions } = await positionPromise;
      const { orders } = await ordersPromise;

      if (orders?.[0]) {
        this.orderColumns = Object.keys(orders[0]);
      }

      await this.setAsyncState({ loading: false, positions, orders });
    } catch (e) {
      console.warn(e);
      await this.setAsyncState({ loading: false, error: e.message });
      window.alert(e.message);
    }
  }
}

const mapStateToProps = (state) => ({
  futuresAllTickers: state.vState.BINANCE_DATA?.futuresAllTickers,
  onFuturesAllTickers: state.vState.BINANCE_DATA?.onFuturesAllTickers,
});

const BalanceDetails = connect(mapStateToProps)(
  withCustomRouter(BalanceDetailsInner)
);

export default BalanceDetails;
