import { getStore } from "../../Providers/store";
import { initSocket } from "../../Services/Socket/socket";
import subscibeCurrentPrices from "../binance/subscibeCurrentPrices";
// import { requestFirebaseNotificationPermission } from "../firebase/firebase-module";
// import api from "../../Services/Api/api";

export const initAuth = () => {
  let store = getStore();

  initSocket();

  if (store) {
  }

  subscibeCurrentPrices();

  // requestFirebaseNotificationPermission()
  //   .then((token) => {
  //     api.put("v1/user/fcm", { token, platform: "web" });
  //   })
  //   .catch(console.log);
};
