/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import moment from "moment";

import { connect } from "react-redux";
import MultiselectCheckbox from "../../../Components/input/MultiselectCheckbox";
import DateInput from "../../../Components/input/date";

class SortAndFilter extends Component {
  constructor(props) {
    super(props);

    this.orderOptions = props.orderOptions;
    this.filterOptions = props.filterOptions;
    if (props.defaultQuery) {
      Object.keys(props.defaultQuery).map((key) => {
        let i = this.filterOptions.findIndex((x) => x.value === key);
        if (i >= 0) {
          this.filterOptions[i].defaultValue = props.defaultQuery[key];
        }
        return null;
      });
    }
  }

  state = {
    selectedTab: this.orderOptions?.length ? "order" : "filter",
    clearFilterTs: 0,
  };

  render() {
    const {
      state: { selectedTab, clearFilterTs },
      props: { query },
    } = this;

    const { sortby, order } = query || {};

    const orderTab = (
      <div className="orderbody">
        {this.orderOptions.map((item) => (
          <div
            className={"orderunit" + (item.value === sortby ? " active" : "")}
            key={item.value}
            onClick={() => this.onOrderClick(item)}
          >
            <div className="orderoption">
              <span className="orderoptionlabel">{item.label}</span>

              {item.value !== sortby ? null : order === 1 ? (
                <span className="ordersubunit">Asc</span>
              ) : order === -1 ? (
                <span className="ordersubunit">Dsc</span>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    );

    const filterTab = (
      <div className="filterelement">
        {this.filterOptions.map((item) => (
          <FilterRow
            item={item}
            key={item.value}
            query={this.props.query}
            onChange={this.props.onChange}
            clearFilterTs={clearFilterTs}
          />
        ))}

        <div className="cfactionwrap">
          <span className="cfbutton" onClick={this.clearFilter.bind(this)}>
            Clear Filter
          </span>
        </div>
      </div>
    );

    return (
      <div className="sortboxunit">
        {this.orderOptions?.length && this.filterOptions?.length ? (
          <div className="sorttabs">
            <div
              className={
                selectedTab === "order" ? "sorttabactive" : "sorttabitem"
              }
              onClick={() => this.setState({ selectedTab: "order" })}
            >
              <span>Order</span>
            </div>

            <div
              className={
                selectedTab === "filter" ? "sorttabactive" : "sorttabitem"
              }
              onClick={() => this.setState({ selectedTab: "filter" })}
            >
              <span>Filter</span>
            </div>
          </div>
        ) : null}
        <div className="sortbody">
          {selectedTab === "order" ? orderTab : filterTab}
        </div>
      </div>
    );
  }

  onOrderClick(item) {
    const currentOrder = parseInt(
      this.props.query.sortby === item.value
        ? this.props.query?.order || item.order
        : item.order
    );

    console.log({
      currentOrder,
      order: item.order,
      updated: -1 * currentOrder,
      props: this.props,
      item,
    });

    if (!this.props.onChange) return;

    this.props.onChange({
      sortby: item.value,
      order: -1 * currentOrder,
    });
    // this.props.onClose();
  }

  clearFilter() {
    let obj = {};

    for (let i = 0; i < this.filterOptions.length; i++) {
      const opt = this.filterOptions[i];

      obj[opt.value] = opt.defaultValue || "";
      console.log({ obj, opt });
    }

    this.props.onChange(obj);
    this.setState({ clearFilterTs: Date.now() });
  }
}

class FilterRowInner extends Component {
  constructor(props) {
    super(props);
    this.onChange.bind(this);
  }

  state = {
    collapsed: !this.props.query?.[this.props.item.value] || "",
    value: this.props.query?.[this.props.item.value] || "",
  };

  timer = null;

  componentDidUpdate(prevProps) {
    if (
      this.props.clearFilterTs &&
      this.props.clearFilterTs !== prevProps.clearFilterTs
    ) {
      this.setState({
        collapsed: !this.props.query?.[this.props.item.value] || "",
        value: this.props.query?.[this.props.item.value] || "",
      });
    }
  }

  onChange(value) {
    this.setState({ value });

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.onChange({ [this.props.item.value]: value?._id || value });
    }, 200);
  }

  CustomInput = ({ value, onClick }) => {
    return (
      <div className="custom-date-input" onClick={onClick}>
        {value ? moment(value).format("MMMM D YY") : "Select Date"}
      </div>
    );
  };

  input() {
    const {
      props: {
        item: { type, options, placeholder, getSuggestions },
      },
      state: { value },
      CustomInput,
    } = this;

    switch (type) {
      case "textInput":
        return (
          <input
            className={"alinputbox"}
            value={value}
            onChange={(e) => this.onChange(e.target.value)}
            placeholder="Type here..."
          />
        );

      case "dateInput":
        return (
          <DateInput
            value={value || undefined}
            onChange={this.onChange.bind(this)}
            CustomInput={CustomInput.bind(this)}
          />
        );

      case "datetime-local":
      case "date":
      case "number":
      case "email":
      case "text":
        return (
          <input
            className={"alinputbox"}
            type={type}
            value={value}
            onChange={(e) => this.onChange(e.target.value)}
            placeholder="Type here..."
          />
        );

      case "multiselect":
        return (
          <MultiselectCheckbox
            className={"alinputbox"}
            placeholder={placeholder || "Select"}
            options={options}
            getSuggestions={getSuggestions}
            onChange={this.onChange.bind(this)}
            delay={10}
            multiselect
            values={value || []}
          />
        );

      default:
        return null;
    }
  }

  render() {
    const {
      props: {
        item: { label },
      },
      state: { collapsed },
    } = this;

    if (collapsed) {
      return (
        <div
          className="sortfilterunit"
          onClick={() => this.setState({ collapsed: !this.state.collapsed })}
        >
          {label}
        </div>
      );
    } else {
      return (
        <div className="sfubox">
          <div className="sfulabelbar">
            <div className="sfulabel">{label}</div>
            <div
              className="sfuclose"
              onClick={() => {
                this.setState({ collapsed: true });
                this.onChange("");
              }}
            >
              <div className="sfucdecor"></div>
            </div>
          </div>

          {this.input()}
        </div>
      );
    }
  }
}

const mapStateToProps = () => ({
});

const FilterRow = connect(mapStateToProps)(FilterRowInner);

SortAndFilter.propTypes = {};

export default SortAndFilter;
