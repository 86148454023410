import React from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import _ from "lodash";
import ReactJson from "react-json-view";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import { PureAppComponent } from "../../../Components/AppComponent";
import api from "../../../Services/Api/api";
import TopNavBar from "../TopNavBar/TopNavBar";

const SCREEN_NAME = "REDIS_SCREEN";

class RedisInner extends PureAppComponent {
  state = { loading: false, error: null };

  render() {
    const {
      state: { loading, error },
      props: { argStr = "", result, cmd = "" },
    } = this;
    return (
      <div
        style={{
          borderRadius: "5px",
          border: "1px solid #3333",
          padding: "10px",
          margin: "10px 0",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div style={{ minWidth: "200px", width: "400px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <b>Redis Database</b>
              {loading ? (
                <span style={{ paddingLeft: "10px" }}>(loading...)</span>
              ) : null}
            </div>
          </div>
          <div className="errormsg">{error}</div>

          <select
            style={{ width: "100%", maxWidth: "400px" }}
            value={""}
            onChange={(e) => this.selectPreset(e.target.value)}
          >
            <option value="" disabled>
              Select From Preset
            </option>
            {this.presets.map((x) => (
              <option value={x.value} key={x.value}>
                {_.startCase(x.value)}
              </option>
            ))}
          </select>
          <br />
          <input
            style={{ width: "100%", maxWidth: "400px" }}
            value={cmd}
            onChange={(e) => this.props.setScreenState({ cmd: e.target.value })}
            placeholder="Command"
          />
          <br />
          <textarea
            style={{ width: "100%", maxWidth: "400px", height: "200px" }}
            value={argStr}
            onChange={(e) =>
              this.props.setScreenState({ argStr: e.target.value })
            }
            placeholder="Comma Separated Args"
          />
          <br />
          <button disabled={loading} onClick={this.submit.bind(this)}>
            Submit
          </button>
        </div>

        <div style={{ margin: "20px", minWidth: "200px", overflowX: "auto" }}>
          <ReactJson
            {...{
              src: result || {},
              name: "Response",
              indentWidth: 8,
              displayDataTypes: false,
            }}
          />
        </div>
      </div>
    );
  }

  presets = [
    {
      value: "getQueuedSymbols",
      cmd: "zRange",
      args: [
        "queue:symbols:future",
        1,
        "+inf",
        {
          BY: "SCORE",
        },
      ],
    },
    {
      value: "getSymbolUpQueue",
      cmd: "zRangeWithScores",
      args: [
        "queue:symbol:BTCUSDT:future:up",
        "-inf",
        "+inf",
        {
          BY: "SCORE",
          REV: false,
          LIMIT: { offset: 0, count: 5 },
        },
      ],
    },
    {
      value: "getSymbolDownQueue",
      cmd: "zRangeWithScores",
      args: [
        "queue:symbol:BTCUSDT:future:down",
        "+inf",
        "-inf",
        {
          BY: "SCORE",
          REV: true,
          LIMIT: { offset: 0, count: 5 },
        },
      ],
    },
    {
      value: "countSymbolUpQueue",
      cmd: "zCount",
      args: ["queue:symbol:BTCUSDT:future:up", "-inf", "+inf"],
    },
    {
      value: "countSymboDownQueue",
      cmd: "zCount",
      args: ["queue:symbol:BTCUSDT:future:down", "-inf", "+inf"],
    },
  ];
  selectPreset(value) {
    if (!value) return;
    const preset = this.presets.find((x) => x.value === value);
    if (!preset) return;
    this.props.setScreenState({
      cmd: preset.cmd,
      argStr: JSON.stringify(preset.args, null, 4),
    });
  }

  async submit() {
    try {
      if (this.state.loading) return;
      await this.setAsyncState({ loading: true, error: null });
      const result = await api.get("v3/app/redis", {
        cmd: this.props.cmd,
        args: JSON.parse(this.props.argStr),
      });
      await this.setAsyncState({ loading: false });
      this.props.setScreenState({ result });
    } catch (e) {
      console.warn(e);
      await this.setAsyncState({ loading: false, error: e.message });
    }
  }
}

const mapStateToProps = (state) => ({
  result: state.vState[SCREEN_NAME]?.result,
  cmd: state.vState[SCREEN_NAME]?.cmd,
  argStr: state.vState[SCREEN_NAME]?.argStr,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export const Redis = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(RedisInner));

const RedisScreen = (props) => {
  return (
    <div className="generalarea">
      <TopNavBar active={"redis"} />
      <Container>
        <Redis {...props} />
      </Container>
    </div>
  );
};

export default RedisScreen;
