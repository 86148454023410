import React, { Component, createRef } from "react";

import update from "immutability-helper";

import "../../../Styles/tradebot-config-classic.css";

import { Modal, Button, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";

class TradebotConfigClassic extends Component {
  state = { visibleModal: null, loading: false };
  configBoxRef = createRef(null);

  setConfig(data) {
    this.props.setConfig(update(this.props.config || {}, { $merge: data }));
  }

  async applyConfig() {
    this.setState({ loading: true });
    await this.props.applyConfig();
    this.setState({ loading: false });
  }

  setPreverifier(obj) {
    const preVerifier = update(this.props.config?.preVerifier || {}, {
      $merge: obj,
    });
    this.setConfig({ preVerifier });
  }

  handleCloseModal() {
    this.setState({ visibleModal: null });
  }

  verifierInput({ item: verifier = {}, index }) {
    const setVerifier = (obj) => {
      let verifiers = this.props.config?.preVerifier?.verifiers || [];
      if (!verifiers[index]) {
        verifiers = update(verifiers, { $merge: { [index]: {} } });
      }

      this.setPreverifier({
        verifiers: update(verifiers || [], {
          [index]: { $merge: obj },
        }),
      });
    };

    const removeVerifier = () => {
      this.setPreverifier({
        verifiers: update(this.props.config?.preVerifier.verifiers || [], {
          $splice: [[index, 1]],
        }),
      });
    };

    return (
      <div
        style={{
          display: "flex",
          width: "-webkit-fill-available",
        }}
      >
        <div className="gp2g" style={{ width: "82px" }}>
          <div>Verify range1</div>
          <input
            type="number"
            value={verifier?.verifyRangeStart || ""}
            onChange={(e) => setVerifier({ verifyRangeStart: e.target.value })}
          ></input>
        </div>
        <div className="gp2g" style={{ width: "82px" }}>
          <div>Verify range2</div>
          <input
            type="number"
            value={verifier?.verifyRangeEnd || ""}
            onChange={(e) => setVerifier({ verifyRangeEnd: e.target.value })}
          ></input>
        </div>
        <div className="gp2g" style={{ width: "82px" }}>
          <div>time1(T2) (ms)</div>
          <input
            type="number"
            value={verifier?.verifyRefTimeMs || ""}
            min={0}
            onChange={(e) => setVerifier({ verifyRefTimeMs: e.target.value })}
          ></input>
        </div>
        <div className="gp2g" style={{ width: "82px" }}>
          <div>time2 (ms)</div>
          <input
            type="number"
            value={verifier?.verifyTimeMs || ""}
            onChange={(e) => setVerifier({ verifyTimeMs: e.target.value })}
          ></input>
        </div>
        <div className="gp2g" style={{ width: "82px" }}>
          <div>Mode</div>
          <select
            style={{ width: "inherit" }}
            value={verifier?.verifierTimeRange || ""}
            onChange={(e) => setVerifier({ verifierTimeRange: e.target.value })}
          >
            <option value="point">Point</option>
            <option value="range">Range</option>
            <option value="timeRange">Time Range</option>
          </select>
        </div>
        {/* <div className="gp2g" style={{ width: "unset" }}>
          <div>Required</div>
          <input
            type="checkbox"
            checked={!!verifier?.required}
            onChange={e => setVerifier({ required: !verifier.required })}
          ></input>
        </div> */}

        <div>
          <button
            onClick={(e) => {
              e.preventDefault();
              removeVerifier();
            }}
          >
            Remove
          </button>
        </div>
      </div>
    );
  }

  scheduleConfigRef = createRef(null);

  addAccount(accountId) {
    if (!this.props.config?.accounts?.includes(accountId)) {
      this.setConfig({
        accounts: [...(this.props.config.accounts || []), accountId],
      });
    }
  }

  removeAccount(accountId) {
    if (this.props.config?.accounts?.includes(accountId)) {
      this.setConfig({
        accounts: this.props.config.accounts.filter((x) => x !== accountId),
      });
    }
  }

  renderAccount(accountId, i) {
    const account = this.props.accounts?.find((x) => x._id === accountId) || {
      _id: accountId,
    };
    const status = account.blocked ? "blocked" : "active";

    return (
      <div
        key={account._id}
        style={{
          minWidth: "200px",
          margin: "2px",
          padding: "2px",
          background: status === "blocked" ? "black" : "white",
          color: status === "blocked" ? "white" : "black",
        }}
      >
        <div>
          <b>{account.name}</b>
          <div>
            apiKey: **{account.apiKey?.substr(-6)}{" "}
            <i onClick={() => window.alert(account.apiKey)}>view</i>
          </div>
          <div>
            apiSecret: **{account.apiSecret?.substr(-6)}{" "}
            <i onClick={() => window.alert(account.apiSecret)}>view</i>
          </div>
          <div style={{ fontSize: "9px" }}>
            {status === "blocked" ? "Blocked" : null}
          </div>
        </div>
        <div style={{ fontSize: "10px" }}>
          <button onClick={() => this.removeAccount(account._id)}>Del</button>
        </div>
      </div>
    );
  }

  render() {
    const {
      props: { config: tradingConfig },
    } = this;

    const configBox = (
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.applyConfig();
          }}
          ref={this.configBoxRef}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <div style={{}}>
              <div className="gp1t">
                <span>Schedule</span>{" "}
                <button
                  onClick={() =>
                    this.setState({ visibleModal: "scheduleConfig" })
                  }
                >
                  Edit
                </button>
              </div>

              <div>
                <div
                  style={{
                    border: "1px solid lightblue",
                    width: "max-content",
                    overflow: "unset",
                  }}
                >
                  <div
                    style={{
                      borderTop: "1px solid lightblue",
                      padding: "0 10px",
                      textAlign: "left",
                      width: "max-content",
                      overflow: "unset",
                    }}
                  >
                    {`C: ${tradingConfig.schedule?.captureHour}:${tradingConfig.schedule?.captureMin}:${tradingConfig.schedule?.captureSec}:0`}
                    <br />
                    {`B: ${tradingConfig.schedule?.buyHour}:${tradingConfig.schedule?.buyMin}:${tradingConfig.schedule?.buySec}:${tradingConfig.schedule?.buyMS}`}
                    <br />
                    {`S: ${tradingConfig.schedule?.sellHour}:${tradingConfig.schedule?.sellMin}:${tradingConfig.schedule?.sellSec}:${tradingConfig.schedule?.sellMS}`}
                  </div>
                </div>
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">Time 1</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  max={-1}
                  value={tradingConfig?.time1}
                  onChange={(e) => this.setConfig({ time1: e.target.value })}
                  required
                />
                <select
                  className="gp1gs"
                  value={tradingConfig?.keynames?.[0] || ""}
                  onChange={(e) =>
                    this.setConfig({
                      keynames: update(tradingConfig?.keynames || [], {
                        $merge: { 0: e.target.value },
                      }),
                    })
                  }
                >
                  <option value="">Select</option>
                  <option value="o">Opening</option>
                  <option value="c">Closing</option>
                </select>
              </div>
            </div>

            <div className="gp1">
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    To be sent to binace api during capture
                  </Tooltip>
                }
              >
                <div className="gp1t">
                  <span>Capture Interval</span>
                </div>
              </OverlayTrigger>

              <div className="gp1g">
                <select
                  className="gp1gi"
                  value={tradingConfig?.captureInterval || ""}
                  onChange={(e) =>
                    this.setConfig({ captureInterval: e.target.value })
                  }
                  required
                >
                  <option value="">Select</option>
                  {[
                    "1m",
                    "3m",
                    "5m",
                    "15m",
                    "30m",
                    "1h",
                    "2h",
                    "4h",
                    "6h",
                    "8h",
                    "12h",
                    "1d",
                    "3d",
                    "1w",
                    "1M",
                  ].map((x) => (
                    <option key={x}>{x}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">Order</div>
              <div className="gp1g">
                <span
                  style={tradingConfig.order === 1 ? { fontWeight: "700" } : {}}
                  onClick={() => this.setConfig({ order: 1 })}
                >
                  Highest
                </span>
                <br />
                <span
                  style={
                    tradingConfig.order === -1 ? { fontWeight: "700" } : {}
                  }
                  onClick={() => this.setConfig({ order: -1 })}
                >
                  Lowest
                </span>
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">Amount</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  min={0}
                  value={tradingConfig?.amount}
                  onChange={(e) => this.setConfig({ amount: e.target.value })}
                  required
                />
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">Order Limit</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  min={1}
                  value={tradingConfig?.limit}
                  onChange={(e) => this.setConfig({ limit: e.target.value })}
                  required
                />
              </div>
            </div>

            {/* <div className="gp1">
              <div className="gp1t">buyFill Limit</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  min={1}
                  value={tradingConfig?.maxBuyFillLimit}
                  onChange={(e) =>
                    this.setConfig({ maxBuyFillLimit: e.target.value })
                  }
                  required
                />
              </div>
            </div> */}

            <div className="gp1">
              <div className="gp1t">qualifier Limit</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  min={1}
                  value={tradingConfig?.qualifiedSymbolLimit}
                  onChange={(e) =>
                    this.setConfig({
                      qualifiedSymbolLimit: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">BuyStopPrice</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  value={tradingConfig?.buyStopPrice || ""}
                  onChange={(e) =>
                    this.setConfig({
                      buyStopPrice: e.target.value,
                    })
                  }
                  required
                />
                <span>%</span>
              </div>
            </div>

            {/* <div className="gp1">
              <div className="gp1t">maxDrop from BuyStop</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  value={tradingConfig?.maxDropFromBuyStop || ""}
                  onChange={(e) =>
                    this.setConfig({
                      maxDropFromBuyStop: e.target.value,
                    })
                  }
                />
                <span>%</span>
              </div>
            </div> */}

            <div className="gp1">
              <div className="gp1t">Buy Offset</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  value={tradingConfig?.buyOffset || ""}
                  onChange={(e) =>
                    this.setConfig({
                      buyOffset: e.target.value,
                    })
                  }
                  required
                />
                <span>%</span>
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">buyCnclStop</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  value={tradingConfig?.buyCancelStop || ""}
                  onChange={(e) =>
                    this.setConfig({
                      buyCancelStop: e.target.value,
                    })
                  }
                  required
                />
                <span>on time2 price</span>
              </div>
            </div>
            <div className="gp1">
              <div className="gp1t">t2Delay</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  value={tradingConfig?.t2Delay || ""}
                  onChange={(e) =>
                    this.setConfig({
                      t2Delay: e.target.value,
                    })
                  }
                />
                <span></span>
              </div>
            </div>
            <div className="gp1">
              <div className="gp1t">maxInAppOpenOrder</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  value={tradingConfig?.maxInAppOpenOrder || ""}
                  onChange={(e) =>
                    this.setConfig({
                      maxInAppOpenOrder: e.target.value,
                    })
                  }
                  required
                />
                <span></span>
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">qckSell Offset</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  value={tradingConfig?.instantSellOfsset || ""}
                  onChange={(e) =>
                    this.setConfig({
                      instantSellOfsset: e.target.value,
                    })
                  }
                  required
                />
                <span>%</span>
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">StopLoss Offset</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  value={tradingConfig?.stopLossOffset || ""}
                  onChange={(e) =>
                    this.setConfig({
                      stopLossOffset: e.target.value,
                    })
                  }
                  required
                />
                <span>%</span>
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">Sell Offset</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  value={tradingConfig?.sellOfsset || ""}
                  onChange={(e) =>
                    this.setConfig({
                      sellOfsset: e.target.value,
                    })
                  }
                  required
                />
                <span>%</span>
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">secondSell SL</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  value={tradingConfig?.secondSellSL || ""}
                  onChange={(e) =>
                    this.setConfig({
                      secondSellSL: e.target.value,
                    })
                  }
                />
                <span>%</span>
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">Buy Timeout</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  min={0}
                  value={tradingConfig?.buyorderTimeout || ""}
                  onChange={(e) =>
                    this.setConfig({
                      buyorderTimeout: e.target.value,
                    })
                  }
                  required
                />
                <span>ms</span>
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">Buy Check At</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  min={0}
                  value={tradingConfig?.recheckOrderTimeLimit || ""}
                  onChange={(e) =>
                    this.setConfig({
                      recheckOrderTimeLimit: e.target.value,
                    })
                  }
                  required
                />
                <span>ms</span>
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">Sell Check At</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  min={0}
                  value={tradingConfig?.recheckOrderTimeLimitAtSell || ""}
                  onChange={(e) =>
                    this.setConfig({
                      recheckOrderTimeLimitAtSell: e.target.value,
                    })
                  }
                  required
                />
                <span>ms</span>
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">Market Type</div>
              <div className="gp1g">
                <select
                  className="gp1gs"
                  value={tradingConfig?.marketType || ""}
                  onChange={(e) =>
                    this.setConfig({
                      marketType: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="spot" disabled>
                    Spot
                  </option>
                  <option value="future">Future</option>
                </select>
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">OrderDirection</div>
              <div className="gp1g">
                <select
                  className="gp1gs"
                  value={tradingConfig?.orderDirection}
                  onChange={(e) =>
                    this.setConfig({
                      orderDirection: e.target.value,
                    })
                  }
                >
                  <option value="">Select</option>
                  <option value="LONG">Long</option>
                  <option value="SHORT">Short</option>
                </select>
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">Sell Fees</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  value={tradingConfig?.sellFees || ""}
                  onChange={(e) =>
                    this.setConfig({
                      sellFees: e.target.value,
                    })
                  }
                  required
                />
                <span>%</span>
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">BlackList At</div>
              <div className="gp1g">
                <input
                  className="gp1gi"
                  type="Number"
                  value={tradingConfig?.autoBlackListSymbolAt || ""}
                  onChange={(e) =>
                    this.setConfig({
                      autoBlackListSymbolAt: e.target.value,
                    })
                  }
                  required
                />
                <span>%</span>
              </div>
            </div>

            <div className="gp1">
              <div className="gp1t">Action</div>
              <div className="gp1g">
                <Button variant="primary" type="submit">
                  Update
                </Button>
              </div>
            </div>
          </div>
        </form>

        <div className="centerify">
          <div style={{ overflowX: "scroll" }}>
            {tradingConfig?.preVerifier?.verifiers?.map((x, i) => (
              <div
                key={i}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {this.verifierInput({ item: x, index: i })}
              </div>
            ))}
            <input
              type="checkbox"
              checked={!!tradingConfig?.preVerifier?.active}
              onChange={() =>
                this.setPreverifier({
                  active: !tradingConfig?.preVerifier?.active,
                })
              }
            />
            <label>Activate</label>
            {"  "}
            <input
              style={{ width: "50px" }}
              type="number"
              value={tradingConfig?.preVerifier?.maxRetry || ""}
              onChange={(e) =>
                this.setPreverifier({
                  maxRetry: e.target.value,
                })
              }
            />
            <label>maxRetry</label>
            {"  "}
            <select
              value={tradingConfig?.preVerifier?.verifierMode || ""}
              onChange={(e) =>
                this.setPreverifier({ verifierMode: e.target.value })
              }
            >
              <option value="all">Match All</option>
              <option value="any">Match Any</option>
            </select>{" "}
            <button
              onClick={() =>
                this.setPreverifier({
                  verifiers: [
                    ...(tradingConfig?.preVerifier?.verifiers || []),
                    {
                      verifierTimeRange: "point",
                    },
                  ],
                })
              }
            >
              Add More Verifier
            </button>
          </div>
        </div>

        <div className="centerify">
          <div>
            <div>
              <b>Accounts: </b>
            </div>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {tradingConfig?.accounts?.map((x, i) => this.renderAccount(x))}
          </div>

          <select
            value=""
            onChange={(e) => {
              this.addAccount(e.target.value);
            }}
          >
            <option value="" disabled>
              Add Account
            </option>
            {this.props.accounts?.map((x) => (
              <option value={x._id} key={x._id}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );

    const scheduleConfigModal = (
      <Modal
        show={this.state.visibleModal === "scheduleConfig"}
        onHide={this.handleCloseModal.bind(this)}
        dialogClassName="daywiserate-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Schedule Config</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ScheduleConfigEdit
            {...{
              key: this.state.visibleModal || "null",
              ref: this.scheduleConfigRef,
              tradingConfig,
              setConfig: this.setConfig.bind(this),
              close: this.handleCloseModal.bind(this),
            }}
          />
        </Modal.Body>
      </Modal>
    );

    return (
      <div
        style={{
          borderRadius: "5px",
          border: "1px solid #3333",
          padding: "10px",
          margin: "10px 0",
        }}
      >
        {configBox}
        {scheduleConfigModal}
      </div>
    );
  }
}

class ScheduleConfigEdit extends Component {
  state = {
    schedule: this.props.tradingConfig.schedule || {},
  };

  scheduleRow({ item, index }) {
    const _select = (opt) => (
      <CustomSelect
        {...{
          data: {
            ...opt,
            index,
            item,
            value: item[opt.k],
          },
          onChange: (v) => {
            this.setState({
              schedule: update(this.state.schedule, {
                $merge: { [opt.k]: v },
              }),
            });
          },
        }}
      />
    );

    return (
      <tr key={item.uid}>
        <td>{_select({ n: 24, a: "every", k: "captureHour" })}</td>
        <td>{_select({ n: 60, a: "every", k: "captureMin" })}</td>
        <td>{_select({ n: 60, a: "every", k: "captureSec" })}</td>
        <td>{_select({ n: 24, a: "next", k: "buyHour" })}</td>
        <td>{_select({ n: 60, a: "next", k: "buyMin" })}</td>
        <td>{_select({ n: 60, a: "next", k: "buySec" })}</td>
        <td>{_select({ n: 1000, a: null, k: "buyMS" })}</td>
        <td>{_select({ n: 24, a: "next", k: "sellHour" })}</td>
        <td>{_select({ n: 60, a: "next", k: "sellMin" })}</td>
        <td>{_select({ n: 60, a: "next", k: "sellSec" })}</td>
        <td>{_select({ n: 1000, a: null, k: "sellMS" })}</td>
        <td style={{ width: "210px" }}>{this.state.ts}</td>
      </tr>
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.setConfig({ schedule: this.state.schedule });
    this.props.close();
  }

  render() {
    const {
      props: { close },
      state: { schedule },
    } = this;

    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Table striped bordered hover>
          <tbody>
            <tr>
              <th>Capture Hour</th>
              <th>Capture Min</th>
              <th>Capture Sec</th>
              <th>Buy Hour</th>
              <th>Buy Min</th>
              <th>Buy Sec</th>
              <th>Buy MiliSec</th>
              <th>Sell Hour</th>
              <th>Sell Min</th>
              <th>Sell Sec</th>
              <th>Sell MiliSec</th>
              <th>Action</th>
            </tr>
            {this.scheduleRow({ item: schedule })}
          </tbody>
        </Table>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ marginLeft: "10px" }}>
            <Button type={"reset"} onClick={close} variant="secondary">
              Cancel
            </Button>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <Button type={"submit"}>OK</Button>
          </div>
        </div>
      </form>
    );
  }
}

const CustomSelect = ({ data: { value }, onChange }) => {
  return (
    <input
      style={{ width: "50px" }}
      value={value || ""}
      onChange={(e) => {
        let value = e.target.value;
        // if (value && isNaN(value)) value = "*";
        // // else if (value && parseInt(value) >= n) value = n - 1;
        // else if (value === "0") value = "0";
        // else value = parseInt(value);
        onChange(value);
      }}
      required
    />
  );
};

const mapStateToProps = (state) => ({
  accounts: state.vState["ACCOUNTS_SCREEN"]?.accounts,
});

export default connect(mapStateToProps)(TradebotConfigClassic);
