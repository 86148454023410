import React from "react";
import TradebotController from "./TradebotController";

class TradebotDash extends React.Component {
  render() {
    return (
      <div className="tradebot-dash">
        <TradebotController {...this.props} />
      </div>
    );
  }
}

export default TradebotDash;
