import React from "react";
import { connect } from "react-redux";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import { PureAppComponent } from "../../../Components/AppComponent";
import api from "../../../Services/Api/api";
import { Button } from "react-bootstrap";

class TradebotSymbols extends PureAppComponent {
  state = { symbols: null, loading: false, error: null, newSymbolsStr: "" };

  componentDidMount() {
    this.onMount();
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.tradebot?.data?.config.marketType !==
      this.props.tradebot?.data?.config?.marketType
    ) {
      this.load();
    }
  }

  async insertSymbols() {
    try {
      let symbols = this.state.newSymbolsStr
        .split(/(,|\n| )/)
        .map((x) => x.trim())
        .filter((x, i, arr) => !!x && x !== "," && arr.indexOf(x) === i)
        .map((x) => ({
          name: x,
          marketType: this.props.tradebot?.data?.config?.marketType,
        }));
      if (!symbols.length) return null;

      console.log({ symbols });
      this.setAsyncState({ error: null, loading: true });
      await api.post("v3/symbol", { botId: this.props.tradebot._id, symbols });
      await this.load();
    } catch (e) {
      window.alert("Error inserting symbols: " + e.message);
      this.setAsyncState({ error: e.message, loading: false });
    }
  }

  async updateSymbols(symbols) {
    try {
      this.setAsyncState({ error: null, loading: true });
      await api.put("v3/symbol", { symbols });
      await this.load();
    } catch (e) {
      window.alert("Error updating symbols: " + e.message);
      this.setAsyncState({ error: e.message, loading: false });
    }
  }

  async deleteSymbol(symbolId) {
    try {
      this.setAsyncState({ error: null, loading: true });
      await api.delete(`v3/symbol/${symbolId}`);
      await this.load();
    } catch (e) {
      window.alert("Error deleting symbol: " + e.message);
      this.setAsyncState({ error: e.message, loading: false });
    }
  }

  renderSymbol(symbol) {
    const status = symbol.blocked
      ? "blocked"
      : new Date(symbol.blockedTill).getTime() > Date.now()
      ? "temp"
      : "active";

    return (
      <div
        key={symbol._id}
        style={{
          width: "142px",
          margin: "2px",
          padding: "2px",
          background:
            status === "blocked"
              ? "black"
              : status === "temp"
              ? "lightgray"
              : "white",
          color: status === "blocked" ? "white" : "black",
        }}
      >
        <div>
          <b>{symbol.name}</b>
          <div style={{ fontSize: "9px" }}>
            {status === "blocked"
              ? "Blocked"
              : status === "temp"
              ? `Blocked Till: ${new Date(symbol.blockedTill).toLocaleString()}`
              : null}
          </div>
        </div>
        <div style={{ fontSize: "10px" }}>
          <button
            onClick={() => {
              if (
                window.confirm(
                  `Are you sure you want to delete ${symbol.name}?`
                )
              )
                this.deleteSymbol(symbol._id);
            }}
          >
            Del
          </button>
          <button
            onClick={() =>
              this.updateSymbols([{ _id: symbol._id, blocked: true }])
            }
          >
            Block
          </button>
          <button
            onClick={() =>
              this.updateSymbols([
                { _id: symbol._id, blocked: false, blockedTill: 0 },
              ])
            }
          >
            Unblock
          </button>
        </div>
      </div>
    );
  }

  render() {
    const {
      props: { tradebot },
      state: { symbols, loading, error },
    } = this;
    return (
      <div
        style={{
          borderRadius: "5px",
          border: "1px solid #3333",
          padding: "10px",
          margin: "10px 0",
        }}
      >
        <div>
          <b>Symbols</b>
          {loading ? (
            <span style={{ paddingLeft: "10px" }}>(loading...)</span>
          ) : null}
        </div>
        <div>Market Type: {tradebot?.data?.config?.marketType}</div>
        <div className="errormsg">{error}</div>

        <div className="flex">
          <textarea
            placeholder="Add symbols separated by comma, newLine or space"
            style={{ width: "100%" }}
            value={this.state.newSymbolsStr}
            onChange={(e) =>
              this.setAsyncState({ newSymbolsStr: e.target.value })
            }
          ></textarea>
          <Button disabled={loading} onClick={() => this.insertSymbols()}>
            Insert
          </Button>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {symbols?.map(this.renderSymbol.bind(this))}
        </div>
      </div>
    );
  }

  loadThrottleTimer = null;
  async load(fields) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(fields);
    }, 200);
  }

  async retrieveData(fields) {
    try {
      await this.setAsyncState({ loading: true, error: null });
      const { symbols } = await api.get("v3/symbol", {
        filter: {
          where: {
            botId: this.props.tradebot._id,
            marketType: this.props.tradebot.data?.config?.marketType,
          },
        },
      });
      await this.setAsyncState({ loading: false, symbols });
    } catch (e) {
      console.warn(e);
      await this.setAsyncState({ loading: false, error: e.message });
    }
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "general") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(TradebotSymbols));
