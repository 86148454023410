import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

import UserAction from "../../Stores/redux/User/Actions";
import navigationModule from "../../Modules/navigation/navigationModule";
import { isLoggedIn } from "../../Stores/redux/User/Selectors";
import { initAuth } from "../../Modules/auth/startup";

const LoginScreen = (props) => {
  useEffect(() => {
    if (props.isLoggedIn) props.history.replace("/");
    else {
      setTimeout(() => {
        handleSubmit();
      }, 0);
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    try {
      let nextScreen = navigationModule.getQuery("nextScreen");
      nextScreen =
        nextScreen && !nextScreen.match("login") && !nextScreen.match("signup")
          ? nextScreen
          : "/";

      let res;
      while (1) {
        let key = window.prompt("Enter Access Key");
        if (key && key === process.env.REACT_APP_AUTH_KEY) {
          res = { user: { _id: key } };
          break;
        }
      }

      if (res?.user) {
        props.setUser(res.user);

        setTimeout(() => {
          initAuth();

          props.history.replace(nextScreen);
        }, 200);
      } else {
      }
    } catch (e) {
      console.log(e);
      window.alert(e.message);
    }
  };

  return (
    <div>
      <p>Not Logged In</p>
      <Button onClick={handleSubmit}>Enter Access Key</Button>
    </div>
  );
};

LoginScreen.propTypes = {
  setUser: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(UserAction.setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
