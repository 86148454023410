const navigationModule = {
  redirectTo: (path) => {
    window.location = path;
  },

  gotoLogin: () => {
    window.location = "/login?nextScreen=" + window.location.pathname;
  },

  matchCurrentPath: (path) => {
    if (!path) return false;

    let r1 = new RegExp(`^${path}/+.*`);
    return !!(window.location.pathname + "/").match(r1);
  },

  getQuery: (key) => {
    let searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(key);
  },

  addQuery: (keys, values, history) => {
    if (typeof keys === "string") {
      keys = [keys];
    }
    if (typeof values === "string") {
      values = [values];
    }

    let pathname = window.location.pathname;
    let searchParams = new URLSearchParams(window.location.search);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = values[i];

      searchParams.set(key, value);
    }

    history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  },

  removeQuery: (keys, history) => {
    if (typeof keys === "string") {
      keys = [keys];
    }

    let searchParams = new URLSearchParams(window.location.search);
    let pathname = window.location.pathname;

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      searchParams.delete(key);
    }

    history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  },
};

export default navigationModule;
