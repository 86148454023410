import { combineReducers } from "redux";
import configureStore from "./CreateStore";
import rootSaga from "../../Sagas";

import { reducer as UserReducer } from "./User/Reducers";
import { reducer as UnPersistReducer } from "./UnPersist/Reducers";
import { reducer as PersistReducer } from "./Persist/Reducers";

const reduxStore = () => {
  const rootReducer = combineReducers({
    /**
     * Register your reducers here.
     * @see https://redux.js.org/api-reference/combinereducers
     */
    user: UserReducer,
    vState: UnPersistReducer,
    pState: PersistReducer,
  });

  return configureStore(rootReducer, rootSaga);
};

export default reduxStore;
