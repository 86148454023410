import React from "react";
import { connect } from "react-redux";
import { Button, Container, Nav } from "react-bootstrap";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import AppComponent from "../../../Components/AppComponent";
import api from "../../../Services/Api/api";
import Tradebot from "./Tradebot";

class Tradebots extends AppComponent {
  componentDidMount() {
    this.onMount();
    this.load();
  }

  async updateTradebot(_id, payload) {
    try {
      await api.put(`v3/tradebot/${_id}`, payload);
      await this.load();
    } catch (e) {
      window.alert("Error updating tradebot: " + e.message);
    }
  }

  async startTradebot(_id, payload) {
    try {
      await api.put(`v3/tradebot/${_id}/start`, payload);
      await this.load();
    } catch (e) {
      window.alert("Error starting tradebot: " + e.message);
    }
  }

  async stopTradebot(_id, payload) {
    try {
      await api.put(`v3/tradebot/${_id}/stop`, payload);
      await this.load();
    } catch (e) {
      window.alert("Error stopping tradebot: " + e.message);
    }
  }

  async addTradebot() {
    try {
      let payload = {
        config: { name: `bot-${Date.now().toString().substr(-3)}` },
      };
      await api.post(`v3/tradebot`, payload);
      await this.load();
    } catch (e) {
      window.alert("Error adding tradebot: " + e.message);
    }
  }

  async deleteTradebot(_id) {
    try {
      await api.delete(`v3/tradebot/${_id}`);
      await this.load();
    } catch (e) {
      window.alert("Error deleting tradebot: " + e.message);
    }
  }

  async markOrderClose(filter) {
    try {
      await api.put(`v3/trade/binanceOrder/close`, { filter });
      return true;
    } catch (e) {
      window.alert("Error closing orders: " + e.message);
      return null;
    }
  }

  render() {
    const {
      props: { tradebots, selectedTradebotIndex },
    } = this;

    const tradebot = tradebots?.[selectedTradebotIndex];

    const tradebotTabs = (
      <Nav variant="tabs">
        {tradebots?.map((tradebot, i) => (
          <Nav.Item
            key={tradebot._id || i}
            onClick={() =>
              this.props.setScreenState(
                {
                  selectedTradebotIndex: i,
                },
                true
              )
            }
          >
            <Nav.Link active={selectedTradebotIndex === i}>
              {tradebot?.data?.config?.name || tradebot?._id}
            </Nav.Link>
          </Nav.Item>
        ))}
        <Nav.Item>
          <Nav.Link>
            <Button size="sm" onClick={() => this.addTradebot()}>
              Add
            </Button>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    );

    return (
      <Container>
        {tradebotTabs}

        {tradebot ? (
          <div key={tradebot._id}>
            <Tradebot
              {...{
                tradebot,
                updateTradebot: (payload) =>
                  this.updateTradebot(tradebot._id, payload),
                startTradebot: () => this.startTradebot(tradebot._id),
                stopTradebot: () => this.stopTradebot(tradebot._id),
                deleteTradebot: () => this.deleteTradebot(tradebot._id),
                markOrderClose: (filter) => this.markOrderClose(filter),
              }}
            />
          </div>
        ) : null}
      </Container>
    );
  }

  loadThrottleTimer = null;
  async load(fields) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(fields);
    }, 200);
  }

  async retrieveData(fields) {
    try {
      await this.setAsyncState({ loading: true, error: null });

      const { accounts } = await api.get("v3/account");
      this.props.setScreenState({ accounts }, false, "ACCOUNTS_SCREEN");

      const { tradebots } = await api.get("v3/tradebot");
      this.props.setScreenState({ tradebots });

      await this.setAsyncState({ loading: false });
    } catch (e) {
      console.warn(e);
      await this.setAsyncState({ loading: false, error: e.message });
    }
  }
}

const mapStateToProps = (state) => ({
  tradebots: state.vState.general?.tradebots,
  selectedTradebotIndex: state.pState.general?.selectedTradebotIndex,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "general") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(Tradebots));
