import React from "react";
import { connect } from "react-redux";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import AppComponent from "../../../Components/AppComponent";
import Tradebots from "../Tradebots/Tradebots";
import TopNavBar from "../TopNavBar/TopNavBar";

class ConfigsScreen extends AppComponent {
  componentDidMount() {
    this.onMount();
    this.load();
  }

  render() {
    const body = (
      <div className="generalarea">
        <TopNavBar active={"bots"} />
        <div>
          <Tradebots />
        </div>
      </div>
    );

    return body;
  }

  loadThrottleTimer = null;
  async load(fields) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(fields);
    }, 200);
  }

  async retrieveData(fields) {
    try {
    } catch (e) {
      console.warn(e);
      this.props.setScreenState({ dashboardError: e.message });
    }
  }
}

const mapStateToProps = (state) => ({
  screenState: state.vState.dashboard,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "dashboard") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(ConfigsScreen));
