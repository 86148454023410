import React, { Component } from "react";

class Numbox extends Component {
  render() {
    const { append, toFixed, style = {} } = this.props;

    return (
      <span
        style={{
          ...style,
          ...(isNaN(this.props.value)
            ? {}
            : parseFloat(this.props.value) < 0
            ? { color: "red" }
            : parseFloat(this.props.value) > 0
            ? { color: "green" }
            : { color: "gray" }),
        }}
      >
        {isNaN(this.props.value)
          ? "-"
          : toFixed || toFixed === 0
          ? this.props.value?.toFixed?.(toFixed) + (append || "").toString()
          : this.props.value + (append || "").toString()}
      </span>
    );
  }
}

export default Numbox;
