/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { INITIAL_STATE } from "./InitialState";
import { createReducer } from "reduxsauce";
import { UserStoreTypes } from "./Actions";

export const setUser = (state, { user }) => {
  user = user ? JSON.parse(JSON.stringify(user)) : null;

  return {
    ...state,
    user,
  };
};

export const setHeaders = (state, { headers }) => {
  return {
    ...state,
    headers,
  };
};

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [UserStoreTypes.SET_USER]: setUser,
  [UserStoreTypes.SET_HEADERS]: setHeaders,
});
