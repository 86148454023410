import React from "react";
import TopNavBar from "../MainScreen/TopNavBar/TopNavBar";
const WorkInProgress = () => {
  return (
    <div className="generalarea">
      <TopNavBar />
      {window.location.href}
    </div>
  );
};

export default WorkInProgress;
