import React, { Component } from "react";
import AutocompleteInput from "./AutocompleteInput";

class MultiselectCheckbox extends Component {
  onChange(obj) {
    let values = this.props.values?.map((x) => x.value || x) || [];

    let index = values.findIndex((x) => (x.value || x) === obj.value);

    let res = [];
    if (index === -1) {
      res = [...values, obj.value];
    } else {
      res = values.filter((x, i) => i !== index);
    }

    this.props.onChange(res);
    return res;
  }

  getSuggestions = () => {
    if (this.props.options) {
      return this.props.options;
    } else if (this.props.getSuggestions) {
      return this.props.getSuggestions();
    } else {
      return [];
    }
  };

  render() {
    const {
      placeholder,
      showValuesAsPlaceholder = true,
      values,
      //  onChange, options
    } = this.props;

    const selectedItems = values?.map((x) => ({
      value: (x.value || x)?.trim(),
    }));

    return (
      <AutocompleteInput
        className={"alinputbox"}
        placeholder={
          showValuesAsPlaceholder
            ? values?.map((x) => x.value || x).join(", ") || placeholder
            : placeholder
        }
        getSuggestions={this.getSuggestions.bind(this)}
        onSelect={(obj) => {
          this.onChange(obj);
        }}
        delay={100}
        initialValue={""}
        multiselect
        selectedItems={selectedItems}
        showSelectedItemsHeader={false}
        readOnly
      />
    );
  }
}

export default MultiselectCheckbox;
