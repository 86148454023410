import React from "react";
import { Button } from "react-bootstrap";

class TradebotController extends React.Component {
  render() {
    return (
      <div className="action-panel">
        <span>
          Status:{" "}
          {this.props.tradebot?.data?.isRunning ? (
            <span style={{ color: "green", fontWeight: "bold" }}>Running</span>
          ) : (
            <span style={{ color: "red", fontWeight: "bold" }}>Stopped</span>
          )}
        </span>
        <span>({this.props.tradebot?._id})</span>
        <div>
          <Button
            variant="primary"
            size="sm"
            onClick={this.props.startTradebot}
          >
            Start
          </Button>
          <Button variant="primary" size="sm" onClick={this.props.stopTradebot}>
            Stop
          </Button>

          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              if (
                window.confirm(
                  `Are you sure you want to delete "${
                    this.props.tradebot.data?.name || this.props.tradebot._id
                  }" ?`
                )
              )
                this.props.deleteTradebot();
            }}
          >
            Delete
          </Button>

          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              if (
                window.confirm(
                  `Are you sure you want to mark all sells order as closed ? This does not cancel orders or close positions in Binance`
                )
              )
                this.props
                  .markOrderClose({
                    where: {
                      botId: this.props.tradebot._id,
                      clientOrderType: {
                        $in: ["quickSell", "secondSell", "marketSell"],
                      },
                    },
                  })
                  .then((x) => {
                    if (x) window.alert("Sell orders are closed successfully");
                  });
            }}
          >
            Close All Sells
          </Button>

          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              if (
                window.confirm(
                  `Are you sure you want to mark all buy ordesr as closed ? This does not cancel orders or close positions in Binance`
                )
              )
                this.props
                  .markOrderClose({
                    where: {
                      botId: this.props.tradebot._id,
                      clientOrderType: {
                        $in: ["buy"],
                      },
                    },
                  })
                  .then((x) => {
                    if (x) window.alert("Buy orders are closed successfully");
                  });
            }}
          >
            Close All Buys
          </Button>
        </div>
      </div>
    );
  }
}

export default TradebotController;
