import { put, call } from "redux-saga/effects";
import UserActions from "../Stores/redux/User/Actions";
import api from "../Services/Api/api";

export function* fetchMyProfile() {
  try {
    const { user } = yield call(() => api.get("v1/user/self"));

    console.info({ user });

    if (user) yield put(UserActions.setUser(user));
    else throw new Error("Something went wrong");
  } catch (error) {
    console.warn("Error fetching myProfile: ", error.message);
  }
}
