import React from "react";
import { connect } from "react-redux";
import { Container, Button } from "react-bootstrap";
import update from "immutability-helper";
import pLimit from "p-limit";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import { PureAppComponent } from "../../../Components/AppComponent";
import api from "../../../Services/Api/api";
import TopNavBar from "../TopNavBar/TopNavBar";
import SearchBox from "../../Components/SearchBox";
import RenderSymbolQueues from "./RenderSymbolQueues";

const SCREEN_NAME = "ORDER_QUEUE_SCREEN";

class OrderQueueInner extends PureAppComponent {
  state = { loading: false, error: null };

  componentDidMount() {
    this.onMount();
    this.load({ reload: true });
  }

  render() {
    const {
      state: { loading, error },
      props: { selectedSymbols = [], queueLimit = 5 },
    } = this;
    return (
      <div>
        <div
          style={{
            borderRadius: "5px",
            border: "1px solid #3333",
            padding: "10px",
            margin: "10px 0",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  fontSize: "8px",
                }}
              >
                Queue Limit
              </span>
              <input
                type="number"
                value={queueLimit}
                placeholder="Queue Limit"
                onChange={(e) =>
                  this.props.setScreenState({ queueLimit: e.target.value })
                }
              />
            </div>
            <Button
              onClick={() => this.load({ reload: true })}
              size="sm"
              disabled={loading}
            >
              Reload
            </Button>
          </div>
        </div>
        <div
          style={{
            borderRadius: "5px",
            border: "1px solid #3333",
            padding: "10px",
            margin: "10px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            <div>
              <b>Queued Symbols</b>
              {loading ? (
                <span style={{ paddingLeft: "10px" }}>(loading...)</span>
              ) : null}
            </div>

            <div style={{ display: "flex" }}>
              <SearchBox
                initialValue={this.props.symbolSearchQ}
                onChange={(x) => {
                  this.props.setScreenState({ symbolSearchQ: x });
                  this.applyFilter({
                    symbolSearchQ: x,
                    queuedSymbols: this.props.queuedSymbols,
                  });
                }}
              />
            </div>
          </div>
          <div className="errormsg">{error}</div>
          <div
            style={{
              fontSize: "9px",
              overflow: "auto",
              maxHeight: "65px",
            }}
          >
            {selectedSymbols.join(", ")}
          </div>
        </div>
        <RenderSymbolQueues />
      </div>
    );
  }

  applyFilter(props) {
    try {
      const { queuedSymbols = [], symbolSearchQ } = props || this.props;

      let regexp = new RegExp(symbolSearchQ, "i");
      const selectedSymbols = symbolSearchQ
        ? queuedSymbols.filter((x) => regexp.test(x))
        : queuedSymbols;
      console.log({ queuedSymbols, symbolSearchQ, regexp, selectedSymbols });

      this.props.setScreenState({ selectedSymbols });
    } catch (e) {
      this.setAsyncState({ error: e.message });
    }
  }

  query = {};
  defaultQuery = {};
  handleQuery(obj) {
    if (!obj) return null;
    this.query = update(this.query, { $merge: obj });

    this.load({ reload: true });
  }

  loadThrottleTimer = null;
  async load(opt) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(opt);
    }, 200);
  }

  async retrieveData({ reload = true }) {
    try {
      await this.setAsyncState({ loading: true, error: null });
      const { result } = await api.get("v3/app/redis", {
        cmd: "zRange",
        args: [
          "queue:symbols:future",
          1,
          "+inf",
          {
            BY: "SCORE",
          },
        ],
      });

      const queuedSymbols = result;

      if (reload) {
        await this.props.setScreenState({ queuedSymbols, symbolQueuObj: {} });
      }

      await this.applyFilter({
        queuedSymbols,
        symbolSearchQ: this.props.symbolSearchQ,
      });

      await this.loadSymbolQueues(queuedSymbols);

      await this.setAsyncState({ loading: false });
    } catch (e) {
      console.warn(e);
      await this.setAsyncState({ loading: false, error: e.message });
    }
  }

  async loadSymbolQueues(symbols) {
    const marketType = "future";
    const directions = ["up", "down"];
    const cmd = "zRangeWithScores";
    this.symbolQueuObj = {};

    let promises = symbols.map(async (symbol) => {
      const symbolData = {};

      for (let j = 0; j < directions.length; j++) {
        const direction = directions[j];

        const key = `queue:symbol:${symbol}:${marketType}:${direction}`;
        let ranges = ["-inf", "+inf"];
        let options = {
          BY: "SCORE",
          REV: direction === "up" ? false : true,
          LIMIT: { offset: 0, count: this.props.queueLimit || 5 },
        };

        if (options.REV) {
          ranges.reverse();
        }

        const args = [key, ranges[0], ranges[1], options];

        const { result } = await this.requestApi("get", [
          "v3/app/redis",
          {
            cmd,
            args,
          },
        ]);
        let result1 = direction === "up" ? result.reverse() : result;
        symbolData[direction] = result?.map((x) => ({
          ...x,
          value: JSON.parse(x.value),
        }));
      }

      this.symbolQueuObj = update(this.symbolQueuObj || {}, {
        $merge: { [symbol]: symbolData },
      });
      this.updateSymbolQueueObj();
    });

    return Promise.all(promises);
  }

  updateSymbolQueueObjTimer = null;
  updateSymbolQueueObj() {
    clearTimeout(this.updateSymbolQueueObjTimer);
    this.updateSymbolQueueObjTimer = setTimeout(() => {
      // console.log(this.symbolQueuObj);
      this.props.setScreenState({
        symbolQueuObj: this.symbolQueuObj,
      });
    }, 100);
  }

  maxConcurrentApiRequest = 20;
  pLimitRequestApiQueue = pLimit(this.maxConcurrentApiRequest);
  async requestApi(method, args) {
    return this.pLimitRequestApiQueue(() => api[method](...args));
  }
}

const mapStateToProps = (state) => ({
  queuedSymbols: state.vState[SCREEN_NAME]?.queuedSymbols,
  selectedSymbols: state.vState[SCREEN_NAME]?.selectedSymbols,
  symbolSearchQ: state.vState[SCREEN_NAME]?.symbolSearchQ,
  queueLimit: state.vState[SCREEN_NAME]?.queueLimit,
  symbolQueuObj: state.vState[SCREEN_NAME]?.symbolQueuObj,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export const OrderQueue = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(OrderQueueInner));

const OrderQueueScreen = (props) => {
  return (
    <div className="generalarea">
      <TopNavBar active={"queues"} />
      <Container>
        <OrderQueue {...props} />
      </Container>
    </div>
  );
};

export default OrderQueueScreen;
