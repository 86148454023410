import React, { Component } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import Numbox from "../../../Components/etc/numbox";
import moment from "moment";

class BuyFrequencyModal extends Component {
  state = { key: "buy_FILLED", interval: "minute" };
  componentDidMount() {
    this.load();
  }

  load() {
    const { records } = this.props;

    console.log(records);
    const f = {};
    records.map((x) => {
      let refTime = x[this.state.key] || x.statusTimestamps?.[this.state.key];
      if (refTime) {
        const d = new Date(refTime);
        let t =
          this.state.interval == "minute"
            ? d.setSeconds(0, 0)
            : this.state.interval == "hour"
            ? d.setMinutes(0, 0, 0)
            : this.state.interval == "day"
            ? d.setHours(0, 0, 0, 0)
            : d.setSeconds(0, 0, 0);
        t = t.toString();

        const change = x.calculated?.change?.res || 0;

        if (!f[t])
          f[t] = {
            n: 1,
            t: parseInt(t),
            change,
            changes: [change],
            symbols: [x.symbol],
            records: [x],
          };
        else
          f[t] = {
            ...f[t],
            n: f[t].n + 1,
            ["change"]: (f[t]["change"] || 0) + change,
            changes: [...f[t]["changes"], change],
            symbols: [...f[t].symbols, x.symbol],
            records: [...f[t].records, x],
          };
      }
    });

    this.items = Object.values(f);
    this.items = this.items.sort((a, b) => a.t - b.t);
    console.log(this.items);
    this.setState({ ts: Date.now() });
  }

  get body() {
    return (
      <div style={{ width: "100%", overflowX: "auto" }}>
        <Table>
          <tbody>
            <tr>
              <th>Buy Time</th>
              <th>Frequency</th>
              <th>Total Change</th>
              <th>Changes</th>
              <th>Symbols</th>
              <th>Action</th>
            </tr>

            {this.items?.map((x) => (
              <tr
                onClick={() => console.log(x)}
                key={x.t.toString() + this.state.key}
              >
                <td>{moment(new Date(x.t)).format("MM/DD hh:mm a")}</td>
                <td>{x.n}</td>
                <td>
                  <Numbox toFixed={2} append="%" value={x["change"]} />
                </td>
                <td>
                  <div style={{ width: "300px", overflowX: "scroll" }}>
                    {x["changes"].map((x) => (
                      <>
                        <Numbox toFixed={2} value={x} />,
                      </>
                    ))}
                  </div>
                </td>
                <td>
                  <div style={{ width: "300px", overflowX: "scroll" }}>
                    {x.symbols.join()}
                  </div>
                </td>
                <td>
                  <button
                    onClick={() => {
                      this.props.handleQuery({
                        tradeIds: x.records.map((x) => x._id).join(","),
                      });

                      this.props.close();
                    }}
                  >
                    Load
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <Modal
        show={true}
        onHide={this.props.close}
        dialogClassName="daywiserate-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Buy Frequency{" "}
            <select
              value={this.state.key || ""}
              onChange={(e) => {
                this.setState({ key: e.target.value }, () => this.load());
              }}
            >
              <option disabled value="">
                Select
              </option>
              <option>t</option>
              <option>intervalTime</option>
              <option>buy_queued</option>
              <option>buy_FILLED</option>
              <option>buy_NEW</option>
            </select>
            <select
              value={this.state.interval || ""}
              onChange={(e) => {
                this.setState({ interval: e.target.value }, () => this.load());
              }}
            >
              <option disabled value="">
                Select
              </option>
              <option>minute</option>
              <option>hour</option>
              <option>day</option>
            </select>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default BuyFrequencyModal;
