import React from "react";
import { connect } from "react-redux";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import { PureAppComponent } from "../../../Components/AppComponent";
import BalanceChart from "./BalanceChart";
import { Modal, Button } from "react-bootstrap";
import BalanceDetails from "./BalanceDetails";

const SCREEN_NAME = "BALANCES_SCREEN";

class BalanceChartsInner extends PureAppComponent {
  render() {
    const {
      props: { account },
    } = this;
    return (
      <div>
        <div>
          Account: <b>{account.name}</b>{" "}
          <button onClick={() => this.setAsyncState({ detailsModal: true })}>
            View Details
          </button>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {account.recordBalances?.map((balanceType) => (
            <div
              key={balanceType}
              className="balance-chart-container"
              // style={{ margin: "20px 0", maxWidth: "500px" }}
            >
              <BalanceChart {...{ account, balanceType }} />
            </div>
          ))}
        </div>

        <Modal
          show={!!this.state.detailsModal}
          onHide={() => this.setState({ detailsModal: null })}
          dialogClassName={`configbox-modal large`}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Current Account Details <b>{account.name}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BalanceDetails account={account} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ detailsModal: null })}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const BalanceCharts = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(BalanceChartsInner));

export default BalanceCharts;
