import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Chart from "react-apexcharts";
import moment from "moment";
import update from "immutability-helper";
import { Button, Modal, Table } from "react-bootstrap";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import { PureAppComponent } from "../../../Components/AppComponent";
import api from "../../../Services/Api/api";
import Numbox from "../../../Components/etc/numbox";

const SCREEN_NAME = "BALANCES_SCREEN";

class BalanceChartInner extends PureAppComponent {
  state = {
    loading: false,
    error: null,
    unit: "minute",
    binSize: 5,
    limit: "",
    autoReload: false,

    series: [
      {
        name: "candle",
        data: [],
      },
    ],

    options: {
      chart: {
        height: 350,
        type: "candlestick",
      },
      title: {
        text: _.startCase(this.props.balanceType),
        align: "left",
      },
      annotations: {
        xaxis: [
          {
            x: "Oct 06 14:00",
            borderColor: "#00E396",
            label: {
              borderColor: "#00E396",
              style: {
                fontSize: "12px",
                color: "#fff",
                background: "#00E396",
              },
              orientation: "horizontal",
              offsetY: 7,
              text: "Annotation Test",
            },
          },
        ],
      },
      tooltip: {
        enabled: true,
      },
      xaxis: {
        type: "category",
        labels: {
          formatter: function (val) {
            return moment(val).format("MMM DD HH:mm");
          },
        },
      },
      yaxis: {
        // floating: false,
        decimalsInFloat: 0,
        tooltip: {
          enabled: true,
        },
      },
    },
  };

  componentDidMount() {
    this.onMount();
    this.setPreset("1d");
  }

  componentWillUnmount() {
    this.onUnmount();
    clearInterval(this.autoReloadTimer);
  }

  presets = [
    {
      value: "custom1",
      label: "Daily",
      data: {
        binSize: 1,
        unit: "day",
        limit: 30,
      },
    },
    {
      value: "1h",
      label: "1 Hour",
      data: {
        binSize: 1,
        unit: "minute",
        limit: 60 / 1,
      },
    },
    {
      value: "1d",
      label: "1 Day",
      data: {
        binSize: 15,
        unit: "minute",
        limit: (60 * 24) / 15,
      },
    },
    {
      value: "1w",
      label: "1 week",
      data: {
        binSize: 2,
        unit: "hour",
        limit: (24 * 7) / 2,
      },
    },
    {
      value: "1m",
      label: "1 month",
      data: {
        binSize: 8,
        unit: "hour",
        limit: (24 * 30) / 8,
      },
    },
    {
      value: "6m",
      label: "6 month",
      data: {
        binSize: 2,
        unit: "day",
        limit: (30 * 6) / 2,
      },
    },
    {
      value: "1y",
      label: "1 year",
      data: {
        binSize: 4,
        unit: "day",
        limit: parseInt(365 / 4),
      },
    },
  ];

  async setPreset(v) {
    let preset = this.presets.find((x) => x.value === v);
    if (preset) {
      await this.setAsyncState({ ...preset.data, preset: v });
      await this.load({ reload: true });
    }
  }

  autoReloadTimer = null;
  toggleAutoReload() {
    clearInterval(this.autoReloadTimer);
    if (!this.state.autoReload) {
      setInterval(() => {
        this.load({ reload: true });
      }, 30 * 1000);
      this.setState({ autoReload: true });
    } else {
      this.setState({ autoReload: false });
    }
  }

  render() {
    const {
      state: { error, series, loading, binSize, unit, autoReload },
    } = this;

    const data = series?.[0]?.data;
    const firstValue = data?.[0]?.y?.[0];
    const lastValue = data?.[data?.length - 1]?.y?.[3];
    const balanceInfo = (
      <div>
        <Numbox
          {...{
            value: lastValue,
            toFixed: 2,
            style: { fontWeight: "700", fontSize: "25px" },
          }}
        />{" "}
        <Numbox
          {...{
            value: ((lastValue - firstValue) * 100) / firstValue,
            toFixed: 1,
            style: { fontSize: "14px" },
            append: "%",
          }}
        />
      </div>
    );

    const config = (
      <div>
        <select
          value={this.state.preset || ""}
          onChange={(e) => {
            this.setPreset(e.target.value);
          }}
        >
          <option value={""} disabled>
            Select Preset
          </option>
          {this.presets.map((x) => (
            <option value={x.value} key={x.value}>
              {x.label || x.value}
            </option>
          ))}
        </select>
        <input
          type="number"
          value={this.state.limit?.toString() || ""}
          onChange={(e) => {
            let v = e.target.value;
            if (v) v = Math.min(parseInt(v), 1000);
            this.setState({ limit: v, preset: "" });
          }}
          placeholder="Limit"
          style={{ width: "50px" }}
        />
        <input
          type="number"
          value={this.state.binSize?.toString()}
          onChange={(e) =>
            this.setState({ binSize: e.target.value, preset: "" })
          }
          placeholder="BinSize"
          style={{ width: "50px" }}
        />
        <select
          value={this.state.unit || ""}
          onChange={(e) => this.setState({ unit: e.target.value, preset: "" })}
        >
          <option value="" disabled>
            Select
          </option>
          {["minute", "hour", "day", "month", "week", "quarter", "year"].map(
            (unit) => (
              <option key={unit}>{unit}</option>
            )
          )}
        </select>
        <Button
          size="sm"
          disabled={loading || !unit || !binSize}
          onClick={() => this.load({ reload: true })}
        >
          Load
        </Button>
        <Button
          size="sm"
          variant={autoReload ? "success" : "secondary"}
          onClick={() => this.toggleAutoReload()}
        >
          Auto Reload
        </Button>
        <Button
          size="sm"
          variant={"secondary"}
          onClick={() => this.setState({ tableModal: true })}
        >
          Table
        </Button>
      </div>
    );

    return (
      <div>
        <div className="errormsg">{error}</div>
        <div className="flex" style={{ justifyContent: "space-between" }}>
          {balanceInfo}
          {config}
        </div>
        <div>
          <Chart
            options={this.state.options}
            series={this.state.series}
            type="candlestick"
            width="100%"
          />
        </div>

        <Modal
          show={!!this.state.tableModal}
          onHide={() => this.setState({ tableModal: null })}
          dialogClassName={`configbox-modal maxwidth600`}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.account?.name}: {_.startCase(this.props.balanceType)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RenderTable series={this.state.series} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ tableModal: null })}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  loadThrottleTimer = null;
  async load(opt) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(opt);
    }, 500);
  }

  async getData({ payload, account, balanceType }) {
    const metaFilter = {
      where: {
        account: account._id,
        balanceType: balanceType,
      },
    };
    const candleFilter = {
      where: {},
      limit: parseInt(this.state.limit),
    };
    const params = {
      unit: this.state.unit,
      binSize: parseInt(this.state.binSize),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    let { data } = await api.get("v3/balance/candlestick", {
      metaFilter,
      candleFilter,
      params,
      ...payload,
    });

    data = data.map((x) => ({
      x: new Date(x._id.time).getTime(),
      y: [x.o, x.h, x.l, x.c],
    }));

    return data;
  }

  async retrieveData({ reload = true, payload = {} }) {
    try {
      await this.setAsyncState({ loading: true, error: null });
      let data;

      if (this.props.account?._id === "total") {
        let dataList = [];
        for (let i = 0; i < this.props.account.accounts.length; i++) {
          const account = this.props.account.accounts[i];
          data = await this.getData({
            payload,
            account: account,
            balanceType: this.props.balanceType,
          });
          dataList.push(data);
        }

        let combinedData = [];

        let lengths = dataList.map((x) => x.length);
        let baseIndex = lengths.findIndex((y) => y == Math.max(...lengths));
        let base = dataList[baseIndex];
        for (let i = 0; i < base.length; i++) {
          const time = base[i].x;

          let y = [0, 0, 0, 0];

          for (let j = 0; j < dataList.length; j++) {
            const data = dataList[j];
            const record = data.find((x) => x.x === time);
            for (let k = 0; k < record?.y.length; k++) {
              const value = record.y[k];
              y[k] = (y[k] || 0) + (value || 0);
            }
          }

          let rec = { ...base[i], y };
          combinedData.push(rec);
        }

        data = combinedData;
      } else {
        data = await this.getData({
          payload,
          account: this.props.account,
          balanceType: this.props.balanceType,
        });
      }

      let updateObj = {
        series: [
          {
            name: "candle",
            data,
          },
        ],
        loading: false,
      };

      if (reload) {
        await this.setAsyncState(updateObj);
      } else {
        await this.setAsyncState(
          update(updateObj, {
            series: {
              $merge: {
                data: {
                  ...updateObj.series.data,
                  ...(this.state.series.data || []),
                },
              },
            },
          })
        );
      }
    } catch (e) {
      console.warn(e);
      await this.setAsyncState({ loading: false, error: e.message });
    }
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const BalanceChart = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(BalanceChartInner));

const RenderTable = ({ series }) => {
  const data = series?.[0].data.reverse();

  return (
    <div style={{ overflow: "auto" }}>
      <Table striped bordered hover>
        <tbody>
          <tr>
            <th>Time</th>
            <th>Open</th>
            <th>Close</th>
            <th>Change</th>
            <th>Change %</th>
            <th>High</th>
            <th>Low</th>
          </tr>
          {data?.map((item) => {
            const o = item.y?.[0].toFixed(2),
              h = item.y?.[1].toFixed(2),
              l = item.y?.[2].toFixed(2),
              c = item.y?.[3].toFixed(2);

            return (
              <tr key={item.x}>
                <td>{new Date(item.x).toLocaleString()}</td>
                <td>{o}</td>
                <td>{c}</td>
                <td>
                  <Numbox
                    {...{
                      value: c - o,
                      toFixed: 2,
                    }}
                  />
                </td>
                <td>
                  <Numbox
                    {...{
                      value: ((c - o) * 100) / o,
                      toFixed: 2,
                      append: "%",
                    }}
                  />{" "}
                </td>
                <td>{h}</td>
                <td>{l}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default BalanceChart;
