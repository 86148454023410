import { getStore } from "../../Providers/store";
import UnpActions from "../../Stores/redux/UnPersist/Actions";

let existing;

const subscibeCurrentPrices = () => {
  if (existing) {
    return;
  }

  // const client = new WebSocketClient();
  var client = new WebSocket("wss://fstream.binance.com/ws/!ticker@arr");

  client.onerror = function () {
    console.log("Connection Error");
  };

  client.onopen = function () {
    console.log("WebSocket Client Connected");
  };

  client.onclose = function () {
    console.log("echo-protocol Client Closed");
  };

  client.onmessage = function (e) {
    if (typeof e.data === "string") {
      try {
        let store = getStore();
        const arr = JSON.parse(e.data);
        const obj =
          store.getState().vState.BINANCE_DATA?.futuresAllTickers || {};
        // console.log({
        //   len: Object.values(obj).filter((x) => x).length,
        //   obj:JSON.stringify(obj),
        // });

        for (let i = 0; i < arr.length; i++) {
          const el = arr[i];
          obj[el.s] = el;
        }
        // console.log({
        //   len: Object.values(obj).filter((x) => x).length,
        //   updated: obj.DOGEUSDT?.c,
        // });

        store.dispatch(
          UnpActions.setVScreenState("BINANCE_DATA", {
            futuresAllTickers: obj,
            onFuturesAllTickers: Date.now(),
          })
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  // client.on("connectFailed", function (error) {
  //   console.log("Connect Error: " + error.toString());
  // });

  // client.on("connect", function (connection) {
  //   console.log("Wss Connected", uri);

  //   connection.on("error", function (error) {
  //     console.log("Connection Error: " + error.toString());
  //   });

  //   connection.on("close", function () {
  //     console.log("Connection closed ");
  //   });

  //   connection.on("message", function (message) {
  //     if (message.type === "utf8") {
  //       console.log("Received: '" + message.utf8Data + "'");

  //       let store = getStore();
  //       store.dispatch(
  //         UnpActions.setVScreenState("BINANCE_DATA", {
  //           futuresAllTickers: message.utf8Data,
  //         })
  //       );
  //     }
  //   });
  // });

  // client.connect(uri);

  existing = client;
};

export default subscibeCurrentPrices;
