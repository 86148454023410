import "bootstrap/dist/css/bootstrap.min.css";

import React, { Component } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

import createStore from "./Stores/redux";
import RootScreen from "./Views/Root/RootScreen";
import "./Styles/style.css";
import { setStore } from "./Providers/store";

const { store, persistor } = createStore();
setStore(store);

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RootScreen />
        </PersistGate>
      </Provider>
    );
  }
}
