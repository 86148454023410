import React from "react";
import { connect } from "react-redux";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import { PureAppComponent } from "../../../Components/AppComponent";
import Numbox from "../../../Components/etc/numbox";

const SCREEN_NAME = "ORDER_QUEUE_SCREEN";

class RenderSymbolQueueInner extends PureAppComponent {
  renderRow({ item, index, direction, symbol, currentPrice, now }) {
    if (!item) return null;

    const diff = ((item.score - currentPrice) / currentPrice) * 100;
    return (
      <tr
        key={index}
        onClick={() =>
          console.log({ item, index, direction, symbol, currentPrice })
        }
        style={{
          fontSize: "10px",
          width: "150px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <td style={{ color: "gray" }}>{item.value?.orderType}</td>
        <td style={{ color: "gray" }}>
          {((item.value?.exp - now) / (1000 * 60)).toFixed(2)}m
        </td>
        <td style={{ overflow: "hidden", width: "50px" }}>
          <b>{item.score}</b>
        </td>
        <td style={{ color: diff < 0 ? "red" : "green" }}>
          {diff.toFixed(2)}%
        </td>
      </tr>
    );
  }

  render() {
    const {
      props: { data, symbol, currentPrice, openingPrice },
    } = this;

    const now = Date.now();
    return (
      <div
        style={{
          borderRadius: "5px",
          border: "1px solid #3333",
          padding: "10px",
          margin: "10px 0",
          minWidth: "180px",
        }}
      >
        <table>
          <tbody>
            {data?.up?.map((item, i) =>
              this.renderRow({
                item,
                index: i,
                direction: "up",
                symbol,
                currentPrice,
                now,
              })
            )}
          </tbody>
        </table>

        <div>
          {symbol}{" "}
          <span>
            <b>{currentPrice?.toString()}</b>
          </span>{" "}
          <Numbox
            value={((currentPrice - openingPrice) * 100) / openingPrice}
            append="%"
            style={{ fontSize: "8px" }}
            toFixed={1}
          />
        </div>
        <table>
          <tbody>
            {data?.down?.map((item, i) =>
              this.renderRow({
                item,
                index: i,
                direction: "down",
                symbol,
                currentPrice,
                now,
              })
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const RenderSymbolQueue = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(RenderSymbolQueueInner));

export default RenderSymbolQueue;
