import React from "react";
import { connect } from "react-redux";
import { Button,  Modal, Table } from "react-bootstrap";
import _ from "lodash";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import { PureAppComponent } from "../../../Components/AppComponent";
import api from "../../../Services/Api/api";
import Numbox from "../../../Components/etc/numbox";
import TopNavBar from "../TopNavBar/TopNavBar";

const SCREEN_NAME = "TRADES_SCREEN";

class TradeGroupsInner extends PureAppComponent {
  state = { loading: false, error: null, jsonModal: null, limit: 50 };

  componentDidMount() {
    this.onMount();
    this.load({ reload: true });
  }

  tradeGroupCols = [
    { key: "t", render: (x) => new Date(x).toLocaleString() },
    { key: "trades", render: (x) => this.renderTrades(x) },
  ];

  tradeCols = [
    { key: "symbol" },
    // { key: "buyOrder", render: (x) => JSON.stringify(x) },
    // { key: "sellOrder", render: (x) => JSON.stringify(x) },
    {
      key: "quantity",
      render: (field, item) => item.buyOrder?.rawRes?.quantity,
    },
    {
      key: "buyCurrentPrice",
      render: (field, item) => item.buyOrder?.rawReq?.currentPrice,
    },
    {
      key: "buyOffsetPrice",
      render: (field, item) => item.buyOrder?.rawRes?.buyOffsetPrice,
    },
    {
      key: "stopLossPrice",
      render: (field, item) => item.buyOrder?.rawRes?.stopLossPrice,
    },
    // {
    //   key: "purchasePrice",
    //   render: (field, item) => "NA",
    // },
    // {
    //   key: "t1",
    //   render: (field, item) => "NA",
    // },
    // {
    //   key: "purchaseTime",
    //   render: (field, item) => "NA",
    // },
    // {
    //   key: "buyPlaceTime",
    //   render: (field, item) => "NA",
    // },
    {
      key: "buyExp",
      render: (field, item) =>
        new Date(item.buyOrder?.rawRes?.exp).toLocaleString(),
    },
    {
      key: "sellOrderType",
      render: (field, item) => item.sellOrder?.orderType,
    },
    {
      key: "sellOrderPrice",
      render: (field, item) => item.sellOrder?.rawReq?.price,
    },
    {
      key: "sellOrderExp",
      render: (field, item) =>
        new Date(item.sellOrder?.rawReq?.exp).toLocaleString(),
    },
    {
      key: "account",
      render: (field, item) => item?.sellOrder?.rawReq?.account,
    },

    {
      key: "buy-errors",
      render: (field, item) =>
        item.buyOrder?.errorList?.map((x) => x.error).join(", "),
    },
    {
      key: "sell-errors",
      render: (field, item) =>
        item.sellOrder?.errorList?.map((x) => x.error).join(", "),
    },
    {
      key: "buy-statuses",
      render: (field, item) =>
        item.buyOrder?.statuses?.map((x) => x.status).join(", "),
    },
    {
      key: "sell-statuses",
      render: (field, item) =>
        item.sellOrder?.statuses?.map((x) => x.status).join(", "),
    },
    { key: "errorList", render: (x) => x?.map((x) => x.error).join(", ") },
    { key: "statuses", render: (x) => x?.map((x) => x.status).join(", ") },
    {
      key: "change",
      render: (field, item) => (
        <Numbox value={this.calculateChange(item)} toFixed={2} />
      ),
    },
    {
      key: "actions",
      render: (field, item) => (
        <>
          <button onClick={() => this.setState({ jsonModal: item })}>
            View
          </button>
        </>
      ),
    },
  ];

  renderTrades(trades) {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            {this.tradeCols.map((col) => (
              <th key={col.key}>{_.startCase(col.label || col.key)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {trades?.map((item, index) => {
            return (
              <tr key={item._id}>
                {this.tradeCols.map((col) => (
                  <td key={col.key}>
                    {col.render
                      ? col.render(item[col.key], item, index)
                      : item[col.key]}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  render() {
    const {
      state: { loading, error },
      props: { tradeGroups },
    } = this;

    return (
      <div>
        <h3>Trade Groups: {loading ? <span>Loading...</span> : null} </h3>
        <div className="errormsg">{error}</div>

        <Table striped bordered hover>
          <thead>
            <tr>
              {this.tradeGroupCols.map((col) => (
                <th key={col.key}>{_.startCase(col.label || col.key)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tradeGroups?.map((item) => {
              return (
                <tr key={item._id}>
                  {this.tradeGroupCols.map((col) => (
                    <td key={col.key}>{col.render(item[col.key])}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>

        <Button disabled={!!loading} onClick={() => this.loadMore()}>
          Load More
        </Button>

        <Modal
          show={!!this.state.jsonModal}
          onHide={() => this.setState({ jsonModal: null })}
          dialogClassName={`configbox-modal large`}
        >
          <Modal.Header closeButton>
            <Modal.Title>Temporoary Details View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ whiteSpace: "break-spaces" }}>
              {JSON.stringify(this.state.jsonModal, null, 8)}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ jsonModal: null })}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  async loadMore() {
    let lastItem = this.props.tradeGroups?.[this.props.tradeGroups?.length - 1];
    let where = lastItem?.t
      ? {
          t: { $lt: lastItem?.t },
        }
      : {};

    this.load({
      reload: false,
      filter: {
        where,
      },
    });
  }

  loadThrottleTimer = null;
  async load(opt) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(opt);
    }, 200);
  }

  async retrieveData({ reload = true, filter = {} }) {
    try {
      await this.setAsyncState({ loading: true, error: null });
      const { tradeGroups } = await api.get("v3/trade/tradegroup", {
        filter: {
          limit: this.state.limit || 50,
          populate: {
            path: "trades",
            populate: { path: "buyOrder sellOrder" },
          },
          sortby: "t",
          order: -1,
          ...filter,
        },
      });
      await this.setAsyncState({ loading: false });
      if (reload) {
        await this.props.setScreenState({ tradeGroups });
      } else {
        await this.props.setScreenState({
          tradeGroups: [...this.props.tradeGroups, ...tradeGroups],
        });
      }
    } catch (e) {
      console.warn(e);
      await this.setAsyncState({ loading: false, error: e.message });
    }
  }

  calculateChange(item) {
    const buyOrder = item.buyOrder;
    const sellOrder = item.sellOrder;

    const buyOrderPrice =
      parseFloat(buyOrder?.rawRes?.buyOffsetPrice) ||
      parseFloat(buyOrder?.rawRes?.price) ||
      parseFloat(buyOrder?.rawRes?.priceLastTrade);

    const sellOrderPrice =
      parseFloat(sellOrder?.rawRes?.priceLastTrade) ||
      parseFloat(sellOrder?.rawRes?.price);
    if (sellOrder?.rawRes)
      console.log(item._id, { buyOrderPrice, sellOrderPrice, sellOrder, item });
    //if (!buyOrderPrice || !sellOrderPrice) return null;

    return ((sellOrderPrice - buyOrderPrice) / buyOrderPrice) * 100;
  }
}

const mapStateToProps = (state) => ({
  tradeGroups: state.vState[SCREEN_NAME]?.tradeGroups,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export const TradeGroups = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(TradeGroupsInner));

const TradesScreen = (props) => {
  return (
    <div className="generalarea">
      <TopNavBar active={"tradeGroups"} />
      <TradeGroups {...props} />
    </div>
  );
};

export default TradesScreen;
