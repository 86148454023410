import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import update from "immutability-helper";

import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import { PureAppComponent } from "../../../Components/AppComponent";
import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import MultiselectCheckbox from "../../../Components/input/MultiselectCheckbox";
import Numbox from "../../../Components/etc/numbox";
import { Modal } from "react-bootstrap";

const SCREEN_NAME = "TRADES_SCREEN";

class TradeScreenStatistics extends PureAppComponent {
  renderBox(x, opt) {
    if (!x) return null;
    return (
      <div
        key={x.key}
        onClick={() => {
          if (opt?.onClick) opt.onClick();
          else
            this.props.handleQuery(
              x.key == "all" ? { botId: null } : { botId: [x.key] }
            );
        }}
        style={{
          minWidth: opt?.fullLabel ? "109px" : "75px",
          background: "#d0e5f7",
          borderRadius: "4px",
          padding: "4px",
          margin: "0px 3px",
          cursor: "pointer",
        }}
      >
        <div>{x.label}</div>
        {Object.keys(x.data || {}).map((key) => {
          return (
            <div key={key}>
              <span>{opt?.fullLabel ? _.startCase(key) : key?.[0]}</span>:{" "}
              <Numbox {...{ value: x.data[key], toFixed: 2 }} />,{" "}
            </div>
          );
        })}
      </div>
    );
  }

  renderBotGroup(x, index) {
    if (!x) return null;

    const total = {
      label: "Total",
      key: "all",
      data: {},
    };
    const boxes = x.botIds?.map((botId) => {
      const botData = this.props.statistics?.bots?.find((x) => x.key == botId);
      if (!botData?.data) return null;

      Object.keys(botData?.data)?.map((key) => {
        total.data[key] =
          (total.data[key] || 0) + parseFloat(botData.data[key] || 0);
      });

      return botData;
    });

    const handleQuery = this.props.handleQuery;
    return (
      <div
        key={x.uid}
        // onClick={() => this.props.handleQuery({ botId: x.botIds })}
        style={{
          borderRadius: "4px",
          padding: "4px",
          margin: "0px 3px",
          display: "flex",
          border: "1px solid blue",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", right: "1px", top: "5px" }}>
          <button
            onClick={() =>
              this.props.setScreenState(
                {
                  botGroups: update(this.props.botGroups || [], {
                    $splice: [[index, 1]],
                  }),
                },
                true
              )
            }
          >
            X
          </button>
        </div>
        {this.renderBox(total, {
          onClick: () => handleQuery({ botId: x.botIds }),
        })}
        {boxes.map((botData) => {
          return this.renderBox(botData, {});
        })}
      </div>
    );
  }

  get addBotGroupIco() {
    return (
      <div
        onClick={() => this.setAsyncState({ addBotGroup: {} })}
        style={{
          writingMode: "vertical-lr",
          cursor: "pointer",
        }}
      >
        Add Group
      </div>
    );
  }

  render() {
    const {
      props: { statistics = {}, botGroups },
    } = this;

    return (
      <div>
        <div style={{ display: "flex", overflowX: "auto" }}>
          {this.renderBox(statistics.total, { fullLabel: true })}
          {botGroups?.map((x, index) => this.renderBotGroup(x, index))}
          {this.addBotGroupIco}

          {statistics?.bots?.map((x) => {
            return this.renderBox(x);
          })}
        </div>

        <Modal
          show={!!this.state.addBotGroup}
          onHide={() => this.setState({ addBotGroup: null })}
          dialogClassName={`configbox-modal`}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.addBotGroup?.uid ? "Edit" : "Add"} Bot Group
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddNewBotGroup
              botGroup={this.state.addBotGroup}
              onSubmit={(botGroup) => {
                this.setAsyncState({ addBotGroup: false });
                let index = this.props.botGroups?.findIndex(
                  (x) => x.uid === botGroup.uid
                );

                this.props.setScreenState(
                  {
                    botGroups:
                      index > -1
                        ? update(this.props.botGroups || [], {
                            $merge: { [index]: botGroup },
                          })
                        : [...(this.props.botGroups || []), botGroup],
                  },
                  true
                );
              }}
              onCancel={() => this.setAsyncState({ addBotGroup: false })}
              tradebots={this.props.tradebots}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

class AddNewBotGroup extends React.PureComponent {
  state = {
    botIds: [],
    uid: Math.random().toString(),
    ...(this.props.botGroup || {}),
  };

  render() {
    return (
      <div
        style={{
          position: "relative",
          minWidth: "200px",
          background: "#e5e5e5",
          borderRadius: "7px",
        }}
      >
        <MultiselectCheckbox
          className={"alinputbox"}
          showValuesAsPlaceholder={false}
          placeholder={"Select Bots"}
          options={this.props.tradebots?.map((x) => ({
            value: x._id,
            label: x.data?.config?.name,
          }))}
          onChange={(values) => {
            this.setState({ botIds: values });
          }}
          multiselect
          values={this.state.botIds}
        />

        <button onClick={this.props.onCancel}>Cancel</button>
        <button
          onClick={() =>
            this.props.onSubmit({
              botIds: this.state.botIds,
              uid: this.state.uid,
            })
          }
        >
          Ok
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tradebots: state.vState.general?.tradebots,
  botGroups: state.pState[SCREEN_NAME]?.botGroups,
  statistics: state.vState[SCREEN_NAME]?.statistics,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(TradeScreenStatistics));
