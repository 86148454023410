import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import _ from "lodash";
import update from "immutability-helper";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import { PureAppComponent } from "../../../Components/AppComponent";
import api from "../../../Services/Api/api";

const SCREEN_NAME = "APPCONFIG_SCREEN";

class AppConfigInner extends PureAppComponent {
  state = { loading: false, error: null };

  componentDidMount() {
    this.onMount();
    this.load();
  }

  async submit() {
    try {
      if (this.state.loading || !this.state.appConfig) return null;

      this.setAsyncState({ error: null, loading: true });
      await api.put(`v3/app/config`, { appConfig: this.state.appConfig });
      await this.load();
    } catch (e) {
      window.alert("Error updating app config: " + e.message);
      this.setAsyncState({ error: e.message, loading: false });
    }
  }

  allConfigs = [
    {
      key: "intermediateOrderDelay",
      help: "Force delay between 2 orders. This delay might required to avoid, exceeding 50 order par 10 sec, limit error. The default value is 200ms",
      type: "number",
      required: true,
    },
  ];

  render() {
    const {
      state: { loading, error, appConfig },
    } = this;
    return (
      <div
        style={{
          borderRadius: "5px",
          border: "1px solid #3333",
          padding: "10px",
          margin: "10px 0",
        }}
      >
        <h3>App Configs</h3>

        <div className="errormsg">{error}</div>
        {this.allConfigs.map((x) => (
          <div key={x.key}>
            <label>
              {_.startCase(x.key)}:{" "}
              <input
                type={x.type}
                value={appConfig?.[x.key] || ""}
                onChange={(e) =>
                  this.setState({
                    appConfig: update(appConfig || {}, {
                      $merge: { [x.key]: e.target.value },
                    }),
                  })
                }
              />
            </label>
            <div style={{ fontSize: "small", color: "#0003" }}>{x.help}</div>
          </div>
        ))}

        <Button onClick={this.submit.bind(this)} disabled={loading}>
          Update
        </Button>
      </div>
    );
  }

  loadThrottleTimer = null;
  async load(fields) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(fields);
    }, 200);
  }

  async retrieveData(fields) {
    try {
      await this.setAsyncState({ loading: true, error: null });
      const { appConfig } = await api.get("v3/app/config", {});
      await this.setAsyncState({ loading: false, appConfig });
      await this.props.setScreenState({ appConfig });
    } catch (e) {
      console.warn(e);
      await this.setAsyncState({ loading: false, error: e.message });
    }
  }
}

const mapStateToProps = (state) => ({
  appConfig: state.vState[SCREEN_NAME]?.appConfig,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const AppConfig = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(AppConfigInner));

export default AppConfig;
