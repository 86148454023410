import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { isLoggedIn } from "../../Stores/redux/User/Selectors";

class MainScreen extends Component {
  gtourRef = React.createRef();

  render() {
    if (!this.props.isLoggedIn) {
      return <Redirect to="/login" />;
    }

    return (
      <div className={`mainwrapper`}>
        <div className="innerwrapper">{this.props.children}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
