import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateInput = (props) => {
  const { value, onChange, format, CustomInput } = props;

  const date = !value
    ? value
    : format
    ? moment(value, format).toDate()
    : new Date(value);

  return (
    <DatePicker
      selected={date}
      onChange={(date) => onChange(format ? moment(date).format(format) : date)}
      customInput={<CustomInput />}
      popperModifiers={{
        // offset: {
        //   enabled: true,
        //   offset: "5px, 10px"
        // },
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: "viewport",
        },
      }}
    />
  );
};

export default DateInput;
