import React, { Component } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "lodash";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import { logout } from "../../../Modules/auth/logout";

class TopNavBar extends Component {
  logout() {
    logout();
    this.props.history.push("/login");
  }

  links = {
    group1: [
      {
        to: "dashboard",
        label: "Tradebot",
      },
    ],
    group2: [
      { to: "dashboard", label: "Home" },
      { to: "bots" },
      { to: "queues" },
      { to: "trades" },
      { to: "tradeGroups" },
      { to: "balances" },
      { to: "accounts" },
      { to: "redis" },
      { to: "manualOrder" },
    ],
  };

  render() {
    return (
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          {this.links.group1.map((x) => (
            <Navbar.Brand
              key={x.to}
              style={{
                color: this.props.active === x.to ? "#000000" : "#0000008c",
              }}
              onClick={() => this.props.history.push(x.to)}
            >
              {x.label || _.startCase(x.to)}
            </Navbar.Brand>
          ))}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {this.links.group2.map((x) => (
                <Link
                  key={x.to}
                  to={x.to}
                  style={{
                    display: "block",
                    padding: "0.5rem 1rem",
                    color: this.props.active === x.to ? "#000000" : "#0000008c",
                    textDecoration: "none",
                  }}
                >
                  {x.label || _.startCase(x.to)}
                </Link>
              ))}
            </Nav>
            <Nav>
              <Nav.Link onClick={this.logout.bind(this)}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default withCustomRouter(TopNavBar);
