import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { isLoggedIn } from "../../Stores/redux/User/Selectors";
import AppNavigator from "../../Navigators/AppNavigator";
import { initAuth } from "../../Modules/auth/startup";
// import { onMessageListener } from "../../Modules/firebase/firebase-module";

class RootScreen extends Component {
  async componentDidMount() {
    console.log({ isLoggedIn: this.props.isLoggedIn });
    if (this.props.isLoggedIn) {
      initAuth();

      // onMessageListener().then(console.log).catch(console.log);
    }
  }

  render() {
    return (
      <div className={`emfwrapper`}>
        <AppNavigator />
      </div>
    );
  }
}

RootScreen.propTypes = {
  isLoggedIn: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RootScreen);
