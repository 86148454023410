import UserAction from "../../Stores/redux/User/Actions";
import { getSocket, setSocket } from "../../Providers/socket";
import { getStore } from "../../Providers/store";
import api, { removeHeaders } from "../../Services/Api/api";

export const logout = () => {
  console.info("Logging out");
  let store = getStore();

  api
    .put(
      "v1/user/logout",
      {
        refreshToken: localStorage.getItem("x-refresh-token"),
      },
      true
    )
    .catch(console.info);

  let socket = getSocket();
  socket && socket.disconnect();
  setSocket(null);

  if (store) {
    store.dispatch(UserAction.setUser({}));
  }

  removeHeaders();

  localStorage.clear();
  return null;
};
