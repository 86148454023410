import axios from "axios";
import config from "../../Config";
import { logout } from "../../Modules/auth/logout";
import navigationModule from "../../Modules/navigation/navigationModule";

export const reserveKeys = [
  "authorization",
  "x-refresh-token",
  "x-token-expiry-time",
];

let headers = {
  "Content-Type": "application/json",
};
let API_URL = `${config.api.protocol}://${config.api.hostname}/${config.api.apipath}/`;
const DEVELOPMENT_ENV_SLEEP_TIME = 1000;

const getErMsg = (error) => {
  let erroObj = (error && error.response && error.response.data) || {};

  return (
    //erroObj.code === 500 ||
    !(erroObj.message || error.message)
      ? "Something went wrong"
      : erroObj.message || error.message
  );
};

const getErCode = (error) => {
  let erroObj = (error && error.response && error.response.data) || {};

  return erroObj.code;
};

const api = {};

api.download = (link, params = {}) => {
  let qs =
    "?" +
    Object.keys(params)
      .map(function (key) {
        return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
      })
      .join("&");

  window.location.href = API_URL + link + qs;
};

api.get = async (uri, params, noRedirect = false) => {
  if (config.hostName === "localhost") await sleep(DEVELOPMENT_ENV_SLEEP_TIME);

  return axios
    .get(API_URL + uri, {
      headers: Object.assign({}, headers, getHeaders()),
      params,
    })
    .then(async (response) => {
      await processHeader(response.headers);
      return response.data;
    })
    .catch((error) => {
      error.message = getErMsg(error);
      error.code = getErCode(error);
      console.warn(
        `Error in GET:${API_URL + uri} status: ${
          error.response && error.response.status
        } message: ${getErMsg(error)}`
      );
      switch (error.response && error.response.status) {
        case 401:
          !noRedirect && logoutUser();
          break;
        default:
          break;
      }
      throw error;
    });
};

api.post = async (uri, payload, noRedirect = false, opt) => {
  if (config.hostName === "localhost") await sleep(DEVELOPMENT_ENV_SLEEP_TIME);

  return axios
    .post(API_URL + uri, payload, {
      headers: Object.assign({}, headers, getHeaders()),
    })
    .then(async (response) => {
      if (opt?.raw) {
        return response;
      } else {
        await processHeader(response.headers);
        return response.data;
      }
    })
    .catch((error) => {
      error.message = getErMsg(error);
      error.code = getErCode(error);
      console.warn(
        `Error in POST:${API_URL + uri} status: ${
          error.response && error.response.status
        } message: ${getErMsg(error)}`
      );
      switch (error.response && error.response.status) {
        case 401:
          !noRedirect && logoutUser();
          break;
        default:
          break;
      }
      throw error;
    });
};

api.put = async (uri, payload, noRedirect = false) => {
  if (config.hostName === "localhost") await sleep(DEVELOPMENT_ENV_SLEEP_TIME);

  return axios
    .put(API_URL + uri, payload, {
      headers: Object.assign({}, headers, getHeaders()),
    })
    .then(async (response) => {
      await processHeader(response.headers);
      return response.data;
    })
    .catch((error) => {
      error.message = getErMsg(error);
      error.code = getErCode(error);
      console.warn(
        `Error in PUT:${API_URL + uri} status: ${
          error.response && error.response.status
        } message: ${getErMsg(error)}`
      );
      switch (error.response && error.response.status) {
        case 401:
          !noRedirect && logoutUser();
          break;
        default:
          break;
      }
      throw error;
    });
};

api.delete = async (uri, payload, noRedirect = false) => {
  if (config.hostName === "localhost") await sleep(DEVELOPMENT_ENV_SLEEP_TIME);

  return axios
    .delete(API_URL + uri, {
      headers: Object.assign({}, headers, getHeaders()),
      data: payload,
    })
    .then(async (response) => {
      await processHeader(response.headers);
      return response.data;
    })
    .catch((error) => {
      error.message = getErMsg(error);
      error.code = getErCode(error);
      console.warn(
        `Error in DELETE:${API_URL + uri} status: ${
          error.response && error.response.status
        } message: ${getErMsg(error)}`
      );
      switch (error.response && error.response.status) {
        case 401:
          !noRedirect && logoutUser();
          break;
        default:
          break;
      }
      throw error;
    });
};

api.formData = async (
  method,
  uri,
  payload = {},
  callback,
  noRedirect = false
) => {
  if (config.hostName === "localhost") await sleep(DEVELOPMENT_ENV_SLEEP_TIME);

  if (["post", "put"].indexOf(method) === -1) {
    throw new Error("Unsupported method");
  }
  let headers = getHeaders();
  headers["Content-Type"] = "multipart/form-data";

  let axiosConfig = {
    headers,
    onUploadProgress: function (progressEvent) {
      if (!callback) return;

      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      callback(percentCompleted);
    },
  };

  let formData = new FormData();

  Object.keys(payload).map((key) => {
    let value = payload[key];

    value =
      typeof value === "object" && ["photo", "file"].indexOf(key) === -1
        ? JSON.stringify(value)
        : value;

    console.warn(key, typeof value, value);

    formData.append(key, value);
    return value;
  });

  return axios[method](`${API_URL}${uri}`, formData, axiosConfig)
    .then(async (response) => {
      await processHeader(response.headers);
      return response.data;
    })
    .catch((error) => {
      error.message = getErMsg(error);
      error.code = getErCode(error);
      console.warn(
        `Error in POST-FORMDATA:${API_URL + uri} status: ${
          error.response && error.response.status
        } message: ${getErMsg(error)}`
      );
      switch (error.response && error.response.status) {
        case 401:
          !noRedirect && logoutUser();
          break;
        default:
          break;
      }
      throw error;
    });
};

api.getCancelSource = () => axios.CancelToken.source();

api.media = async (data, cb, noRedirect = false, cancelToken) => {
  if (config.hostName === "localhost") await sleep(3000);

  let headers = getHeaders();
  headers["Content-Type"] = "multipart/form-data";
  headers["Accept"] = "application/json";

  let params = {};
  data.fileType && (params.fileType = data.fileType);
  data.public && (params.public = data.public);

  let axiosConfig = {
    headers,
    params,
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      cb && cb(percentCompleted);
    },
  };

  if (cancelToken) axiosConfig.cancelToken = cancelToken;

  var endpoint = API_URL + "v1/files";

  let formData = new FormData();

  if (data.file) {
    formData.append("file", data.file);
  } else {
    throw new Error("Missing valid required input");
  }

  return axios
    .post(endpoint, formData, axiosConfig)
    .then(async (response) => {
      await processHeader(response.headers);
      return response.data;
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error);
        return null;
      }

      error.message = getErMsg(error);
      error.code = getErCode(error);
      console.warn(
        `Error in POST MEDIA:${endpoint} status: ${
          error.response && error.response.status
        } message: ${getErMsg(error)} originalError: ${error}`
      );
      switch (error.response && error.response.status) {
        case 401:
          !noRedirect && logoutUser();
          break;
        default:
          break;
      }
      throw error;
    });
};

api.getPublicFileLink = (id, height, width) => {
  if (!id) return "";

  return `${config.apiUrlFull}v1/files/${id}?height=${height || 0}&width=${
    width || 0
  }`;
};

api.getFileLink = (id, height, width, video = false) => {
  id = id?._id || id;
  if (!id) return "";

  if (video) {
    return `${API_URL}v1/files/${id}`;
  }

  const headers = getHeaders();

  return `${API_URL}v1/files/${id}?accessToken=${
    headers.authorization
  }&height=${height || 0}&width=${width || 0}`;
};

api.redirectToSubdomain = (subdomain, uri = "", params) => {
  let currentSubdomain = getSubdomain();
  var full = window.location.host;

  let url =
    (currentSubdomain
      ? full.replace(currentSubdomain, subdomain)
      : subdomain + "." + full) +
    "/" +
    uri;

  let qs = params && serialize(params);

  url = window.location.protocol + "//" + url + "?" + qs;
  console.info("rediercting: ", url);

  window.location.replace(url);
  // window.location.href = url;
};

const processHeader = async (responHeaders = {}) => {
  for (let i = 0; i < reserveKeys.length; i++) {
    const key = reserveKeys[i];

    if (responHeaders[key]) {
      headers && (headers[key] = responHeaders[key]);
      await localStorage.setItem(key, responHeaders[key]);
    }
  }
};

export const getSubdomain = () => {
  var full = window.location.host;
  var filtered = full.replace(config.hostName, "");
  var parts = filtered.split(".");
  var p1 = parts[0];

  var sub = p1.match(/\W+/) ? null : p1;
  return sub || "";
};

export const getHeaders = () => {
  let result = {};

  let subdomain = getSubdomain();
  if (subdomain) result.subdomain = subdomain;

  for (let i = 0; i < reserveKeys.length; i++) {
    const key = reserveKeys[i];

    let value = localStorage.getItem(key);

    if (value) {
      if (key === "authorization") {
        result[key] = "Bearer " + value;
      } else {
        result[key] = value;
      }
    }
  }
  headers = Object.assign(headers, result);

  return result;
};

export const removeHeaders = async () => {
  for (let i = 0; i < reserveKeys.length; i++) {
    const key = reserveKeys[i];

    localStorage.removeItem(key);
  }
};

const serialize = function (obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

const logoutUser = () => {
  if (
    navigationModule.matchCurrentPath("/login") ||
    navigationModule.matchCurrentPath("/signup")
  )
    return null;

  logout();
};

const sleep = (ms) =>
  new Promise((resolve) =>
    setTimeout(() => {
      resolve(true);
    }, ms)
  );

export default api;
