import { takeLatest, all } from "redux-saga/effects";
import { UserStoreTypes } from "../Stores/redux/User/Actions";
import { fetchMyProfile } from "./UserSaga";

export default function* root() {
  yield all([
    /**
     * @see https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html
     */
    takeLatest(UserStoreTypes.FETCH_MY_PROFILE, fetchMyProfile),
  ]);
}
