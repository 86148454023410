import React from "react";
import { Switch, Route } from "react-router-dom";
import WorkInProgress from "../Views/Common/work-in-progress";
import NotFound404 from "../Views/Common/not-found-404";
import RedirectRoute from "./Components/RedirectRoute";
import MainScreen from "../Views/MainScreen/MainScreen";
import ConfigsScreen from "../Views/MainScreen/Configs/ConfigsScreen";
import AccountsScreen from "../Views/MainScreen/Accounts/AccountsScreen";
import TradesScreen from "../Views/MainScreen/Trades/TradesScreen";
import TradeGroupsScreen from "../Views/MainScreen/Trades/TradeGroupsScreen";
import BalancesScreen from "../Views/MainScreen/Balances/BalancesScreen";
import DashboardScreen from "../Views/MainScreen/Dashboard/DashboardScreen";
import RedisScreen from "../Views/MainScreen/Redis/RedisScreen";
import OrderQueueScreen from "../Views/MainScreen/OrderQueue/OrderQueueScreen";
import ManualOrderScreen from "../Views/MainScreen/ManualOrder/ManualOrderScreen";

function MainScreenNavigator() {
  return (
    <MainScreen key="main-screen">
      <Switch>
        <RedirectRoute path="/" exact pathname="/dashboard" />
        <Route path="/wip" component={WorkInProgress} />
        <Route path="/dashboard" component={DashboardScreen} />
        <Route path="/bots" component={ConfigsScreen} />
        <Route path="/balances" component={BalancesScreen} />
        <Route path="/trades" component={TradesScreen} />
        <Route path="/tradeGroups" component={TradeGroupsScreen} />
        <Route path="/accounts" component={AccountsScreen} />
        <Route path="/redis" component={RedisScreen} />
        <Route path="/queues" component={OrderQueueScreen} />
        <Route path="/manualOrder" component={ManualOrderScreen} />

        <Route path="/" component={NotFound404} />
      </Switch>
    </MainScreen>
  );
}

export default MainScreenNavigator;
