import React from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import { PureAppComponent } from "../../../Components/AppComponent";
import TopNavBar from "../TopNavBar/TopNavBar";
import AppConfig from "./AppConfig";

const SCREEN_NAME = "DASHBOARD_SCREEN";

class DashboardInner extends PureAppComponent {
  render() {
    return (
      <Container>
        <AppConfig />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export const Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(DashboardInner));

const DashboardScreen = (props) => {
  return (
    <div className="generalarea">
      <TopNavBar active={"trades"} />
      <Dashboard {...props} />
    </div>
  );
};

export default DashboardScreen;
