import React from "react";
import { connect } from "react-redux";
import { Button, Container, Form, Modal } from "react-bootstrap";
import _ from "lodash";
import update from "immutability-helper";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import { PureAppComponent } from "../../../Components/AppComponent";
import api from "../../../Services/Api/api";
import TopNavBar from "../TopNavBar/TopNavBar";

const SCREEN_NAME = "ACCOUNTS_SCREEN";

class AccountsInner extends PureAppComponent {
  state = { loading: false, error: null };

  componentDidMount() {
    this.onMount();
    this.load();
  }

  async onAccountAdded() {
    this.load();
  }

  async updateAccount(account, updateObj) {
    try {
      this.setAsyncState({ error: null, loading: true });
      await api.put(`v3/account/${account._id}`, {
        account: { ..._.pick(account, ["apiKey", "apiSecret"]), ...updateObj },
      });
      await this.load();
    } catch (e) {
      window.alert("Error updating account: " + e.message);
      this.setAsyncState({ error: e.message, loading: false });
    }
  }

  updateAccountTimer = {};
  updateAccountWithTrottle(account, updateObj) {
    clearTimeout(this.updateAccountTimer[account._id]);
    this.updateAccountTimer[account._id] = setTimeout(() => {
      this.updateAccount(account, updateObj);
    }, 1000);
  }

  async toggleBalanceRecordType({
    isActive,
    balanceType,
    itemIndex,
    accountId,
  }) {
    try {
      const accounts = this.props.accounts || [];
      const accountIndex = accounts.findIndex(
        (x) => x._id.toString() === accountId.toString()
      );
      const account = accounts[accountIndex];

      const list = account.recordBalances || [];
      const updated = isActive
        ? update(list, { $splice: [[itemIndex, 1]] })
        : update(list, { $push: [balanceType] });

      let updatedAccounts = update(accounts, {
        [accountIndex]: { $merge: { recordBalances: updated } },
      });

      await this.props.setScreenState({ accounts: updatedAccounts });
      this.updateAccountWithTrottle(account, { recordBalances: updated });
    } catch (e) {
      window.alert(e.message);
    }
  }

  async deleteAccount(accountId) {
    try {
      this.setAsyncState({ error: null, loading: true });
      await api.delete(`v3/account/${accountId}`);
      await this.load();
    } catch (e) {
      window.alert("Error deleting account: " + e.message);
      this.setAsyncState({ error: e.message, loading: false });
    }
  }

  renderAccount(account) {
    const status = account.blocked ? "blocked" : "active";

    return (
      <div
        key={account._id}
        style={{
          minWidth: "200px",
          maxWidth: "300px",
          margin: "2px",
          padding: "2px",
          background: status === "blocked" ? "black" : "white",
          color: status === "blocked" ? "white" : "black",
        }}
      >
        <div>
          <AccountName
            account={account}
            submit={(name) => this.updateAccount(account, { name })}
          />
          <AccountLeverage
            account={account}
            submit={(leverage) => this.updateAccount(account, { leverage })}
          />
          <div>
            apiKey: **{account.apiKey?.substr(-6)}{" "}
            <i onClick={() => window.alert(account.apiKey)}>view</i>
          </div>
          <div>
            apiSecret: **{account.apiSecret?.substr(-6)}{" "}
            <i onClick={() => window.alert(account.apiSecret)}>view</i>
          </div>
          <div style={{ fontSize: "9px" }}>
            {status === "blocked" ? "Blocked" : null}
          </div>
        </div>

        <div style={{ fontSize: "small", padding: "7px 0px" }}>
          <div>Record Balance For</div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {[
              "spot",
              "freeSpotUSD",
              "realizedFuture",
              "currentFuture",
              "freeFutureUSD",
            ].map((balanceType) => {
              const itemIndex = account.recordBalances?.findIndex(
                (x) => x === balanceType
              );
              const isActive = itemIndex > -1;

              return (
                <div
                  key={balanceType}
                  style={{
                    padding: "2px",
                    margin: "2px",
                    borderRadius: "4px",
                    border: "1px solid gray",
                    background: isActive ? "lightgreen" : "white",
                  }}
                  onClick={() =>
                    this.toggleBalanceRecordType({
                      isActive,
                      balanceType,
                      itemIndex,
                      accountId: account._id,
                    })
                  }
                >
                  {_.startCase(balanceType)}
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ fontSize: "10px" }}>
          <button
            onClick={() => {
              if (
                window.confirm(
                  `Are you sure you want to delete ${account.name}?`
                )
              )
                this.deleteAccount(account._id);
            }}
          >
            Del
          </button>
          <button
            onClick={() => this.updateAccount(account, { blocked: true })}
          >
            Block
          </button>
          <button
            onClick={() => this.updateAccount(account, { blocked: false })}
          >
            Unblock
          </button>
        </div>
      </div>
    );
  }

  render() {
    const {
      state: { loading, error, visibleModal },
      props: { accounts },
    } = this;
    return (
      <div
        style={{
          borderRadius: "5px",
          border: "1px solid #3333",
          padding: "10px",
          margin: "10px 0",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <b>Accounts</b>
            {loading ? (
              <span style={{ paddingLeft: "10px" }}>(loading...)</span>
            ) : null}
          </div>
          <Button
            onClick={() => this.setState({ visibleModal: "accountInput" })}
            size="sm"
          >
            Add Account
          </Button>
        </div>
        <div className="errormsg">{error}</div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {accounts?.map(this.renderAccount.bind(this))}
        </div>

        <AccountInputModal
          {...{
            visible: visibleModal === "accountInput",
            close: () => this.setAsyncState({ visibleModal: null }),
            onAccountAdded: this.onAccountAdded.bind(this),
          }}
        />
      </div>
    );
  }

  loadThrottleTimer = null;
  async load(fields) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(fields);
    }, 200);
  }

  async retrieveData(fields) {
    try {
      await this.setAsyncState({ loading: true, error: null });
      const { accounts } = await api.get("v3/account", {
        filter: { sortby: "createdAt" },
      });
      await this.setAsyncState({ loading: false });
      await this.props.setScreenState({ accounts });
    } catch (e) {
      console.warn(e);
      await this.setAsyncState({ loading: false, error: e.message });
    }
  }
}

export class AccountInputModal extends PureAppComponent {
  render() {
    return (
      <Modal
        show={this.props.visible}
        onHide={this.props.close}
        dialogClassName="appmodal medium"
      >
        <AccountInput {...this.props} />
      </Modal>
    );
  }
}

class AccountInput extends PureAppComponent {
  state = {
    apiKey: "",
    apiSecret: "",
    name: "",
    leverage: "",
  };

  async handleSubmit(e) {
    try {
      e.preventDefault();

      const { name, apiKey, apiSecret, leverage } = this.state;

      this.setAsyncState({ error: null, loading: true });
      const { account } = await api.post("v3/account", {
        account: { name, apiKey, apiSecret, leverage },
      });

      this.props.onAccountAdded(account);
      this.props.close();
    } catch (e) {
      window.alert("Error adding accounts: " + e.message);
      this.setAsyncState({ error: e.message, loading: false });
    }
  }

  render() {
    return (
      <div>
        <h4>All Accounts</h4>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
              required
              placeholder="Any Random Name"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="apiKey">
            <Form.Label>Api Key</Form.Label>
            <Form.Control
              value={this.state.apiKey}
              onChange={(e) => this.setState({ apiKey: e.target.value })}
              required
              placeholder="API KEY"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="apiSecret">
            <Form.Label>Api Secret</Form.Label>
            <Form.Control
              required
              placeholder="API SECRET"
              value={this.state.apiSecret}
              onChange={(e) => this.setState({ apiSecret: e.target.value })}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="apiSecret">
            <Form.Label>Leverage</Form.Label>
            <Form.Control
              type="number"
              min={1}
              max={150}
              required
              placeholder="Example 3"
              value={this.state.leverage}
              onChange={(e) => this.setState({ leverage: e.target.value })}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
          <Button onClick={this.props.close} variant="cancel" type="reset">
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

const AccountName = ({ account, submit }) => {
  const [name, setName] = React.useState(account.name || "");

  return (
    <div>
      <input value={name} onChange={(e) => setName(e.target.value)} />
      {name !== account.name ? (
        <button onClick={() => submit(name)}>Apply</button>
      ) : null}
    </div>
  );
};

const AccountLeverage = ({ account, submit }) => {
  const [leverage, setLeverage] = React.useState(
    (account.leverage || "").toString()
  );

  return (
    <div>
      <input
        value={leverage}
        placeholder="leverage"
        onChange={(e) => setLeverage(e.target.value)}
        type="number"
      />
      {leverage != account.leverage?.toString() ? (
        <button onClick={() => submit(leverage)}>Apply</button>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  accounts: state.vState[SCREEN_NAME]?.accounts,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export const Accounts = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(AccountsInner));

const AccountsScreen = (props) => {
  return (
    <div className="generalarea">
      <TopNavBar active={"accounts"} />
      <Container>
        <Accounts {...props} />
      </Container>
    </div>
  );
};

export default AccountsScreen;
