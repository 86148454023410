import React from "react";
import { connect } from "react-redux";
import { Button, Container, Form } from "react-bootstrap";
import _ from "lodash";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import { PureAppComponent } from "../../../Components/AppComponent";
import api from "../../../Services/Api/api";
import TopNavBar from "../TopNavBar/TopNavBar";

const SCREEN_NAME = "MANUAL_ORDER_SCREEN";

class ManualOrderInner extends PureAppComponent {
  state = {
    loading: false,
    error: null,
    payload: JSON.stringify(
      {
        symbol: "",
        side: "BUY",
        positionSide: "LONG",
        type: "LIMIT",
        price: "",
        quantity: "0",
      },
      null,
      8
    ),
    symbol: "",
    sid: "BUY",
    positionSide: "LONG",
    price: "",
    quantity: "",
    amount: "",
    res: "",
    accountId: "",
  };

  componentDidMount() {
    this.onMount();
    this.load();
  }

  onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (this.state.loading) return;

      const payload = JSON.parse(this.state.payload);
      const accountId = this.state.accountId;

      if (!accountId) throw new Error("Please select account");

      const account = this.props.accounts.find(
        (x) => x._id.toString() === accountId.toString()
      );

      const confirmed = window.confirm(
        `Please review order payload and confirm.
        account name: ${account.name}
        ${JSON.stringify(payload, null, 8)}`
      );

      if (confirmed) {
        this.setAsyncState({ loading: true, error: null });
        const res = await api.post("v3/account/placeOrder", {
          orderPayload: payload,
          accountId,
        });

        this.setAsyncState({
          res: `${JSON.stringify(res, null, 8)}`,
          loading: false,
        });
      }
    } catch (e) {
      window.alert(e.message);
      this.setAsyncState({ loading: false, error: e.message });
    }
  };

  render() {
    const {
      state: { loading, error, payload, res, accountId },
      props: { accounts },
    } = this;

    return (
      <div>
        <Container>
          <h3>Manual Order: {loading ? <span>Loading...</span> : null} </h3>

          <div className="errormsg">{error}</div>

          <Form onSubmit={this.onSubmit.bind(this)}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Account</Form.Label>
              <Form.Control
                as="select"
                value={accountId || ""}
                onChange={(e) => this.setState({ accountId: e.target.value })}
              >
                <option value="" disabled>
                  Select
                </option>
                {accounts?.map((x) => (
                  <option key={x._id} value={x._id}>
                    {x.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>payload</Form.Label>
              <Form.Control
                as="textarea"
                rows="12"
                value={payload || ""}
                onChange={(e) => this.setState({ payload: e.target.value })}
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>

          <div style={{ margin: "20px", lineBreak: "white-space" }}>
            {JSON.stringify(res)}
          </div>
        </Container>
      </div>
    );
  }

  loadThrottleTimer = null;
  async load(fields) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(fields);
    }, 200);
  }

  async retrieveData(fields) {
    try {
      await this.setAsyncState({ loading: true, error: null });
      const { accounts } = await api.get("v3/account", {
        filter: { sortby: "createdAt" },
      });
      await this.setAsyncState({ loading: false });
      await this.props.setScreenState({ accounts }, false, "ACCOUNTS_SCREEN");
    } catch (e) {
      console.warn(e);
      await this.setAsyncState({ loading: false, error: e.message });
    }
  }
}

const mapStateToProps = (state) => ({
  accounts: state.vState.ACCOUNTS_SCREEN?.accounts,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export const ManualOrder = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(ManualOrderInner));

const ManualOrderScreen = (props) => {
  return (
    <div className="generalarea">
      <TopNavBar active={"manualOrder"} />
      <ManualOrder {...props} />
    </div>
  );
};

export default ManualOrderScreen;
