import React from "react";
import { connect } from "react-redux";
import { Button, Container, Modal, Table } from "react-bootstrap";
import _ from "lodash";
import update from "immutability-helper";
import ReactJson from "react-json-view";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import { PureAppComponent } from "../../../Components/AppComponent";
import api from "../../../Services/Api/api";
import Numbox from "../../../Components/etc/numbox";
import TopNavBar from "../TopNavBar/TopNavBar";
import FilterBar from "../../Components/SortAndFilter/FilterBar";
import BuyFrequencyModal from "./BuyFrequencyModal";
import MultiselectCheckbox from "../../../Components/input/MultiselectCheckbox";
import TradeScreenStatistics from "./TradeScreenStatistics";

const SCREEN_NAME = "TRADES_SCREEN";

class TradesInner extends PureAppComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      jsonModal: null,
    };

    const tradeCols = [
      { key: "_id" },
      { key: "t", render: (x) => new Date(x).toLocaleString() },
      { key: "intervalTime", render: (x) => new Date(x).toLocaleString() },
      { key: "symbol" },
      {
        key: "quantity",
        render: (field, item) => item.buyOrder?.rawRes?.quantity,
      },
      {
        key: "botId",
      },
      {
        key: "symbolIndex",
      },
      {
        key: "buyCurrentPrice",
        render: (field, item) => item.buyOrder?.rawReq?.currentPrice,
      },
      {
        key: "buyOffsetPrice",
        render: (field, item) => item.buyOrder?.rawRes?.buyOffsetPrice,
      },
      {
        key: "stopLossPrice",
        render: (field, item) => item.buyOrder?.rawRes?.stopLossPrice,
      },
      {
        key: "buyOrderPrice",
        render: (field, item) => this.renderPrice(item.buyBinanceOrder),
      },
      {
        key: "stopLossOrderPrice",
        render: (field, item) => this.renderPrice(item.stopLossBinanceOrder),
      },
      {
        key: "stopLoss2OrderPrice",
        render: (field, item) => this.renderPrice(item.stopLoss2BinanceOrder),
      },
      {
        key: "sellOrderPrice",
        render: (field, item) => this.renderPrice(item.sellBinanceOrder),
      },

      {
        key: "buyNewStatusAt",
        render: (field, item) =>
          item.statusTimestamps?.buy_NEW
            ? new Date(item.statusTimestamps?.buy_NEW).toLocaleString()
            : null,
      },
      {
        key: "buyFilledStatusAt",
        render: (field, item) =>
          item.statusTimestamps?.buy_FILLED
            ? new Date(item.statusTimestamps?.buy_FILLED).toLocaleString()
            : null,
      },
      {
        key: "secondSellPlacedAt",
        render: (field, item) =>
          item.statusTimestamps?.secondSell_NEW
            ? new Date(item.statusTimestamps?.secondSell_NEW).toLocaleString()
            : null,
      },
      {
        key: "secondSellPlacedPrice",
        render: (field, item) => item.calculated?.secondSellPlacedPrice,
      },

      {
        key: "marketSellFilledAt",
        render: (field, item) =>
          item.statusTimestamps?.marketSell_FILLED
            ? new Date(
                item.statusTimestamps?.marketSell_FILLED
              ).toLocaleString()
            : null,
      },
      {
        key: "marketSellPrice",
        render: (field, item) => item.calculated?.marketSellPrice,
      },

      {
        key: "buyUpdatedAt",
        render: (field, item) =>
          new Date(item.buyBinanceOrder?.updateTime).toLocaleString(),
      },
      {
        key: "buyExp",
        render: (field, item) =>
          new Date(item.buyOrder?.rawRes?.exp).toLocaleString(),
      },
      {
        key: "sellOrderExp",
        render: (field, item) =>
          new Date(item.sellOrder?.rawReq?.exp).toLocaleString(),
      },
      {
        key: "sellType",
        render: (field, item) => item.calculated?.sellType,
      },
      {
        key: "account",
        render: (field, item) => item?.sellOrder?.rawReq?.account,
      },

      { key: "errorList", render: (x) => x?.map((x) => x.error).join(", ") },
      {
        key: "statuses",
        render: (x) => (
          <div style={{ maxHeight: "185px", overflowY: "auto" }}>
            {x?.map((x) => x.status).join(", ")}
          </div>
        ),
      },
      {
        key: "change",
        render: (field, item) => (
          <Numbox value={item.calculated.change.res} toFixed={2} />
        ),
      },
      {
        key: "currentChange",
        render: (field, item) => <CurrentChange item={item} />,
      },
      {
        key: "actions",
        render: (field, item) => (
          <>
            <button onClick={() => this.setState({ jsonModal: item })}>
              View
            </button>
          </>
        ),
      },
    ];
    this.tradeCols = tradeCols
      .sort((a, b) => (a.key > b.key ? 1 : -1))
      .map((x) => ({ ...x, value: x.key, label: _.startCase(x.key) }));

    let colKeyMap = {};
    for (let i = 0; i < this.tradeCols.length; i++) {
      const col = this.tradeCols[i];
      colKeyMap[col.key] = col;
    }

    this.tradeColKeyMap = colKeyMap;
  }

  componentDidMount() {
    this.onMount();
    this.load({ reload: true });
  }

  query = {
    statuses: ["buy_FILLED"],
    timeFrom: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
  };
  defaultQuery = {};
  handleQuery(obj) {
    if (!obj) return null;
    this.query = update(this.query, { $merge: obj });

    this.load({ reload: true });
  }

  orderOptions = [
    {
      label: "Trade Time",
      value: "t",
      order: -1,
    },
  ];

  getStatusList() {
    const clientOrderTypes = [
      "buy",
      "stopLoss",
      "stopLoss2",
      "quickSell",
      "secondSell",
      "marketSell",
    ];
    const orderStatuses = [
      // "placed",
      "queued",
      "processing",
      "NEW",
      "CANCELED",
      "PARTIALLY_FILLED",
      "FILLED",
      "EXPIRED",
      "ERROR",
      "CANCEL_ERROR",
    ];
    let statuses = [];
    for (let i = 0; i < clientOrderTypes.length; i++) {
      const clientOrderType = clientOrderTypes[i];
      for (let j = 0; j < orderStatuses.length; j++) {
        const orderStatus = orderStatuses[j];
        const status = `${clientOrderType}_${orderStatus}`;
        statuses.push({ label: status, value: status });
      }
    }

    return statuses;
  }

  getBots() {
    console.log(this.props.tradebots);
    return this.props.tradebots?.map((x) => ({
      _id: x._id,
      value: x._id,
      label: x?.data?.config?.name,
    }));
  }

  filterOptions = [
    {
      label: "Statuses",
      value: "statuses",
      type: "multiselect",
      options: this.getStatusList(),
    },
    {
      label: "Statuses Not In",
      value: "statusesNotIn",
      type: "multiselect",
      options: this.getStatusList(),
    },
    { label: "Trade Id", value: "_id", type: "textInput" },
    { label: "Trade Ids", value: "tradeIds", type: "textInput" },
    {
      label: "Bot Id",
      value: "botId",
      type: "multiselect",
      getSuggestions: () => this.getBots(),
    },
    { label: "Symbol", value: "symbol", type: "textInput" },
    { label: "Error", value: "errorListQ", type: "textInput" },

    { label: "Time From", value: "timeFrom", type: "datetime-local" },
    { label: "Tme To", value: "timeTo", type: "datetime-local" },
  ];

  toggleTableColSelection({ key, isActive, colIndex }) {
    const {
      props: { visibleTradeColumns = [] },
    } = this;
    const updated = isActive
      ? update(visibleTradeColumns, { $splice: [[colIndex, 1]] })
      : update(visibleTradeColumns, { $push: [key] });

    this.props.setScreenState(
      {
        visibleTradeColumns: updated,
      },
      true
    );
    console.log({ visibleTradeColumns, key, isActive, colIndex });
  }

  tableColSection() {
    const {
      props: { visibleTradeColumns = [] },
    } = this;

    return (
      <div style={{ display: "flex", flexWrap: "wrap", fontSize: "small" }}>
        {this.tradeCols?.map((col) => {
          const colIndex = visibleTradeColumns.findIndex((x) => x === col.key);
          const isActive = colIndex > -1;

          return (
            <div
              key={col.key}
              style={{
                padding: "2px",
                margin: "2px",
                borderRadius: "4px",
                border: "1px solid gray",
                background: isActive ? "lightgreen" : "white",
              }}
              onClick={() =>
                this.toggleTableColSelection({
                  isActive,
                  key: col.key,
                  colIndex,
                })
              }
            >
              {_.startCase(col.key)}
            </div>
          );
        })}
      </div>
    );
  }

  renderTrades(trades) {
    const {
      props: { visibleTradeColumns },
    } = this;

    return (
      <RenderTable
        {...{
          visibleTradeColumns,
          trades,
          tradeColKeyMap: this.tradeColKeyMap,
        }}
      />
    );
  }

  renderLeftItem() {
    return (
      <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        <div style={{ paddingRight: "10px" }}>
          Loaded: {this.props.trades?.length}{" "}
        </div>
        <LoadMoreBtn
          disabled={!!this.state.loading}
          onSubmit={() => {
            console.log("loading");
            this.loadMore();
          }}
          inputOnly
        />
        <Button size="sm" onClick={() => this.loadMore()}>
          Load More
        </Button>
        <Button size="sm" onClick={() => this.load({ reload: true })}>
          Reload
        </Button>
        <Button
          variant="secondary"
          size="sm"
          onClick={() =>
            this.setState({ buyFrequency: !this.state.buyFrequency })
          }
        >
          Buy Frequency
        </Button>
        <div
          style={{
            position: "relative",
            width: "112px",
            background: "#e5e5e5",
            borderRadius: "7px",
          }}
        >
          <MultiselectCheckbox
            className={"alinputbox"}
            showValuesAsPlaceholder={false}
            placeholder={"Table Columns"}
            options={this.tradeCols}
            onChange={(values) => {
              this.props.setScreenState(
                {
                  visibleTradeColumns: values,
                },
                true
              );
            }}
            multiselect
            values={this.props.visibleTradeColumns}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      state: { loading, error },
      props: { trades },
    } = this;

    return (
      <div>
        <Container>
          <h3>Trades: {loading ? <span>Loading...</span> : null} </h3>
          <FilterBar
            query={this.query}
            setQuery={this.handleQuery.bind(this)}
            filterOptions={this.filterOptions}
            orderOptions={this.orderOptions}
            defaultQuery={this.defaultQuery}
            leftItem={this.renderLeftItem.bind(this)}
          />
          <div className="errormsg">{error}</div>
        </Container>

        <TradeScreenStatistics handleQuery={this.handleQuery.bind(this)} />

        {JSON.stringify(this.query)}
        {/* {this.tableColSection()} */}

        <div style={{ width: "100%", overflowX: "auto" }}>
          {this.renderTrades(trades)}
        </div>

        <LoadMoreBtn
          buttonOnly={true}
          style={{ width: "100%" }}
          disabled={!!loading}
          onSubmit={({ limit }) => {
            this.loadMore();
          }}
        />

        <Modal
          show={!!this.state.jsonModal}
          onHide={() => this.setState({ jsonModal: null })}
          dialogClassName={`configbox-modal large`}
        >
          <Modal.Header closeButton>
            <Modal.Title>Temporoary Details View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactJson
              {...{
                src: this.state.jsonModal,
                name: "trade",
                indentWidth: 8,
              }}
            />
            {/* <div style={{ whiteSpace: "break-spaces" }}>
              {JSON.stringify(this.state.jsonModal, null, 8)}
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ jsonModal: null })}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {this.state.buyFrequency ? (
          <BuyFrequencyModal
            records={trades}
            close={() => this.setState({ buyFrequency: false })}
            handleQuery={this.handleQuery.bind(this)}
          />
        ) : null}
      </div>
    );
  }

  async loadMore() {
    let lastItem = this.props.trades?.[this.props.trades?.length - 1];
    let order = this.query.order || -1;
    let sortby = this.query.sortby || "t";
    if (sortby !== "t") {
      throw new Error(`Sortby ${sortby} is not handeling by loadMore function`);
    }

    let where = {};
    if (lastItem?.t) {
      let ts = new Date(lastItem.t)?.getTime();
      let excludedIds = this.props.trades
        .filter((x) => new Date(x.t).getTime?.() === ts)
        .map((x) => x?._id.toString());

      where = {
        t: { [order === -1 ? "$lte" : "$gte"]: lastItem?.t },
        _id: { $nin: excludedIds },
      };
    }

    this.load({
      reload: false,
      filter: {
        where,
      },
    });
  }

  loadThrottleTimer = null;
  async load(opt) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(opt);
    }, 200);
  }

  async retrieveData({ reload = true, filter = {} }) {
    try {
      await this.setAsyncState({ loading: true, error: null });

      let $and = [filter.where || {}];

      let defaultFilter = {
        limit: this.props.limit || 50,
        populate: {
          path: "buyOrder sellOrder buyBinanceOrder sellBinanceOrder stopLossBinanceOrder stopLoss2BinanceOrder",
        },
        sort: { t: -1, botId: 1, symbolIndex: 1 },
        raw: { ...this.defaultQuery, ...this.query },
      };

      if (this.query.sortby && this.query.order) {
        defaultFilter = {
          ...defaultFilter,
          sort: Object.assign({}, defaultFilter.sort, {
            [this.query.sortby]: this.query.order,
          }),
        };
      }

      if (this.query.statuses?.length) {
        $and.push({ "statuses.status": { $in: this.query.statuses } });
      }

      if (this.query.statusesNotIn?.length) {
        $and.push({ "statuses.status": { $nin: this.query.statusesNotIn } });
      }

      if (this.query.timeFrom) {
        $and.push({ t: { $gte: new Date(this.query.timeFrom).getTime() } });
      }

      if (this.query.timeTo) {
        $and.push({ t: { $lte: new Date(this.query.timeTo).getTime() } });
      }

      if (this.query._id) {
        $and.push({ _id: this.query._id });
      }

      if (this.query.tradeIds) {
        const tradeIds = this.query.tradeIds
          .split(",")
          .map((x) => x.trim())
          .filter((x) => !!x);
        if (tradeIds.length) $and.push({ _id: { $in: tradeIds } });
      }

      if (this.query.botId) {
        $and.push({
          botId:
            typeof this.query.botId == "string"
              ? this.query.botId
              : { $in: this.query.botId },
        });
      }

      if (this.query.symbol) {
        $and.push({ symbol: this.query.symbol });
      }

      const { trades: rawTrades } = await api.get("v3/trade/trade", {
        filter: {
          ...defaultFilter,
          ...filter,
          where: { $and: $and },
        },
      });

      const trades = this.preProcessTrades(rawTrades);

      await this.setAsyncState({ loading: false });
      if (reload) {
        await this.props.setScreenState({ trades });
      } else {
        await this.props.setScreenState({
          trades: [...this.props.trades, ...trades],
        });
      }

      const { tradebots } = await api.get("v3/tradebot");
      this.props.setScreenState({ tradebots }, false, "general");

      setTimeout(() => {
        this.getStatistics({ tradebots });
      }, 200);
    } catch (e) {
      console.warn(e);
      await this.setAsyncState({ loading: false, error: e.message });
    }
  }

  preProcessTrades(rawTrades) {
    return rawTrades?.map((x) => {
      let statusTimestamps = {};
      for (let i = 0; i < x.statuses?.length; i++) {
        const item = x.statuses[i];
        statusTimestamps[item.status] = item.ts;
      }

      const calculated = {
        secondSellPlacedPrice:
          x.sellOrder?.orderType === "secondSell"
            ? x.sellOrder.rawReq?.price
            : null,
        marketSellPrice:
          x.sellBinanceOrder?.clientOrderType === "marketSell"
            ? x.sellBinanceOrder?.averagePrice
            : null,
        sellType:
          x.stopLossBinanceOrder?.orderStatus === "FILLED"
            ? x.stopLossBinanceOrder?.clientOrderType
            : x.stopLoss2BinanceOrder?.orderStatus === "FILLED"
            ? x.stopLoss2BinanceOrder?.clientOrderType
            : x.sellOrder?.orderType,

        change: this.calculateChange(x),
      };

      return {
        ...x,
        intervalTime: new Date(x.t).getTime() - 60 * 1000,
        statusTimestamps,
        calculated,
      };
    });
  }

  getStatistics({ tradebots }) {
    const trades = this.props.trades;

    let botData = {
      all: {},
    };

    for (let i = 0; i < tradebots.length; i++) {
      const bot = tradebots[i];
      botData[bot._id] = {};
    }

    for (let i = 0; i < trades?.length; i++) {
      const trade = trades[i];
      const change = trade.calculated?.change;
      let realised = 0,
        current = 0;
      if (change?.res) {
        if (change.sellFilled) realised = change.res;
      }
      current = change.res;

      botData.all = {
        realised: (botData.all.realised || 0) + realised,
        current: (botData.all.current || 0) + current,
      };

      if (trade.botId) {
        const botId = trade.botId;
        if (!botData[botId]) {
          botData[botId] = {
            realised: 0,
            current: 0,
          };
        }

        botData[botId] = {
          realised: (botData[botId].realised || 0) + realised,
          current: (botData[botId].current || 0) + current,
        };
      }
    }

    let bots = [];
    let total = {};

    Object.keys(botData).map((key) => {
      let x = botData[key];
      if (key === "all") {
        total = {
          key,
          label: "Total",
          data: x,
        };
      } else {
        let tradebot = tradebots.find((x) => x._id == key);
        bots.push({
          key,
          label: tradebot?.data?.config?.name || key,
          data: x,
        });
      }
    });

    const statistics = {
      total,
      bots,
    };
    console.log({ statistics });

    this.props.setScreenState({ statistics });
  }

  renderPrice(item) {
    return (
      <Numbox
        value={parseFloat(item?.priceLastTrade) || parseFloat(item?.price)}
      />
    );
  }

  calculateChange(item) {
    const buyOrder = item?.buyBinanceOrder;
    const sellOrder = item?.sellBinanceOrder;
    const stopLossOrder = item?.stopLossBinanceOrder;
    const stopLoss2Order = item?.stopLoss2BinanceOrder;

    const isShort = buyOrder?.positionSide === "SHORT";

    let consideredSellOrder =
      stopLossOrder?.orderStatus === "FILLED"
        ? stopLossOrder
        : stopLoss2Order?.orderStatus === "FILLED"
        ? stopLoss2Order
        : sellOrder;

    let sellFilled = consideredSellOrder?.orderStatus === "FILLED";

    const buyOrderPrice =
      parseFloat(buyOrder?.priceLastTrade) || parseFloat(buyOrder?.price);

    const sellOrderPrice =
      parseFloat(consideredSellOrder?.priceLastTrade) ||
      parseFloat(consideredSellOrder?.price);

    let res = ((sellOrderPrice - buyOrderPrice) / buyOrderPrice) * 100;
    res = isShort ? -1 * res : res;
    return {
      value: res,
      res: sellFilled ? res : null,
      sellFilled,
    };
  }
}

const mapStateToProps = (state) => ({
  tradebots: state.vState.general?.tradebots,
  trades: state.vState[SCREEN_NAME]?.trades,
  visibleTradeColumns: state.pState[SCREEN_NAME]?.visibleTradeColumns,
  limit: state.vState[SCREEN_NAME]?.limit,
  futuresAllTickers: state.vState.BINANCE_DATA?.futuresAllTickers,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export const Trades = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(TradesInner));

class RenderTable extends PureAppComponent {
  i = 0;
  render() {
    const {
      props: { visibleTradeColumns, trades, tradeColKeyMap },
    } = this;
    console.log("rerender table", ++this.i);

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            {visibleTradeColumns?.map((key) => {
              const col = tradeColKeyMap[key];
              if (!col) return null;
              return <th key={col.key}>{_.startCase(col.label || col.key)}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {trades?.map((item, index) => {
            return (
              <tr key={item._id}>
                {visibleTradeColumns?.map((key) => {
                  const col = tradeColKeyMap[key];
                  if (!col) return null;
                  return (
                    <td key={col.key}>
                      {col.render
                        ? col.render(item[col.key], item, index)
                        : item[col.key]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

class LoadMoreBtnInner extends React.PureComponent {
  render() {
    if (this.props.buttonOnly) {
      return (
        <Button
          onClick={() => this.props.onSubmit({ limit: this.props.limit })}
          type="submit"
          style={this.props.style}
          size="sm"
          disabled={!!this.props.disabled}
        >
          Load More
        </Button>
      );
    } else if (this.props.inputOnly) {
      return (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.props.onSubmit({ limit: this.props.limit });
          }}
        >
          <input
            type={"number"}
            placeholder="Limit"
            max={2000}
            value={this.props.limit}
            onChange={(e) => {
              const value = e.target.value;
              this.props.setScreenState({
                limit: value
                  ? Math.min(2000, parseInt(value)).toString()
                  : value,
              });
            }}
          />
        </form>
      );
    }
    return (
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.props.onSubmit({ limit: this.props.limit });
          }}
        >
          Limit:{" "}
          <input
            type={"number"}
            max={2000}
            value={this.props.limit}
            onChange={(e) =>
              this.props.setScreenState({ limit: e.target.value })
            }
          />{" "}
          <Button
            type="submit"
            style={this.props.style}
            size="sm"
            disabled={!!this.props.disabled}
          >
            Load More
          </Button>
        </form>
      </>
    );
  }
}

export const LoadMoreBtn = connect(
  (state) => ({
    limit: state.vState[SCREEN_NAME]?.limit,
  }),
  mapDispatchToProps
)(withCustomRouter(LoadMoreBtnInner));

class CurrentChangeInner extends React.PureComponent {
  calculateCurrentChange(item) {
    const buyOrderPrice =
      parseFloat(item?.buyBinanceOrder?.priceLastTrade) ||
      parseFloat(item?.buyBinanceOrder?.price);
    const isShort = item?.buyBinanceOrder?.positionSide === "SHORT";
    return (
      ((this.props.futuresAllTickers?.[item.symbol]?.c - buyOrderPrice) /
        buyOrderPrice) *
      100 *
      (isShort ? -1 : 1)
    );
  }

  render() {
    return (
      <Numbox
        value={this.calculateCurrentChange(this.props.item)}
        toFixed={2}
      />
    );
  }
}

const CurrentChange = connect((state) => ({
  futuresAllTickers: state.vState.BINANCE_DATA?.futuresAllTickers,
  onFuturesAllTickers: state.vState.BINANCE_DATA?.onFuturesAllTickers,
}))(CurrentChangeInner);

const TradesScreen = (props) => {
  return (
    <div className="generalarea">
      <TopNavBar active={"trades"} />
      <Trades {...props} />
    </div>
  );
};

export default TradesScreen;
