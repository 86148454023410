const appConfig =  {
  appName: "TradeBot",
  socketUrl: process.env.REACT_APP_SOCKETURL || "/",

  api: {
    protocol: process.env.REACT_APP_PROTOCOL||'http',
    hostname: process.env.REACT_APP_HOSTNAME||'localhost:3001',
    apipath: process.env.REACT_APP_APIPATH||'api'
  },
};

export default appConfig;