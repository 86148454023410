import React from "react";

import AppComponent from "../../../Components/AppComponent";
// import TradebotConfig from "./TradebotConfig";
import TradebotDash from "./TradebotDash";

import "../../../Styles/tradebot.css";
import TradebotConfigClassic from "./TradebotConfigClassic";
import TradebotSymbols from "./TradebotSymbols";

class Tradebot extends AppComponent {
  state = {
    config: this.props.tradebot.data?.config || {},
  };

  render() {
    return (
      <div className="tradebot-v1">
        <TradebotDash {...this.props} />

        <TradebotConfigClassic
          {...{
            config: this.state.config,
            setConfig: (config) => {
              this.setState({ config });
            },
            applyConfig: () =>
              this.props.updateTradebot({ config: this.state.config }),
          }}
        />
        <TradebotSymbols {...{ tradebot: this.props.tradebot }} />
      </div>
    );
  }
}

export default Tradebot;
