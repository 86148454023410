import React from "react";
import { connect } from "react-redux";
import { Container, Nav } from "react-bootstrap";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import UnpActions from "../../../Stores/redux/UnPersist/Actions";
import PActions from "../../../Stores/redux/Persist/Actions";
import { PureAppComponent } from "../../../Components/AppComponent";
import api from "../../../Services/Api/api";
import TopNavBar from "../TopNavBar/TopNavBar";
import BalanceCharts from "./BalanceCharts";

const SCREEN_NAME = "BALANCES_SCREEN";

class BalancesInner extends PureAppComponent {
  state = { loading: false, error: null };

  componentDidMount() {
    this.onMount();
    this.load();
  }

  render() {
    const {
      state: { loading, error, selectedAccount },
      props: { accounts },
    } = this;

    const totalAccount = {
      _id: "total",
      name: "Total",
      accounts: accounts,
      recordBalances: ["currentFuture", "freeFutureUSD"],
    };

    const accountTabs = (
      <Nav variant="tabs">
        {accounts &&
          [totalAccount, ...accounts]?.map((account, i) => (
            <Nav.Item
              key={account._id || i}
              onClick={() =>
                this.setState({
                  selectedAccount: account,
                })
              }
            >
              <Nav.Link active={selectedAccount === account._id}>
                {account.name}
              </Nav.Link>
            </Nav.Item>
          ))}
      </Nav>
    );

    return (
      <div>
        <h3>Balances: {loading ? <span>Loading...</span> : null} </h3>
        <div>{error}</div>

        {accountTabs}

        {selectedAccount ? (
          <BalanceCharts account={selectedAccount} key={selectedAccount._id} />
        ) : null}
      </div>
    );
  }

  loadThrottleTimer = null;
  async load(fields) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.loadAccounts(fields);
    }, 200);
  }

  async loadAccounts(fields) {
    try {
      await this.setAsyncState({ loading: true, error: null });
      const { accounts } = await api.get("v3/account", {
        filter: { sortby: "createdAt" },
      });
      await this.setAsyncState({ loading: false });
      await this.props.setScreenState({ accounts }, false, "ACCOUNTS_SCREEN");
    } catch (e) {
      console.warn(e);
      await this.setAsyncState({ loading: false, error: e.message });
    }
  }
}

const mapStateToProps = (state) => ({
  accounts: state.vState["ACCOUNTS_SCREEN"]?.accounts,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export const Balances = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(BalancesInner));

const BalancesScreen = (props) => {
  return (
    <div className="generalarea">
      <TopNavBar active={"balances"} />
      <Container>
        <Balances {...props} />
      </Container>
    </div>
  );
};

export default BalancesScreen;
